import $ from "jquery";
window.$ = $;
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import breakpoints from "./helpers/breakpoints.js";
import waypoints from "./helpers/jquery.waypoints.js";
import ee from "./helpers/experience-editor.js";
import debounce from "./helpers/debounce.js";

const debug = false,
    featuredPeopleSelector = ".js-featured-people";

var breakpoint;

export default {init: init, reset: reset, selector: featuredPeopleSelector};

function init() {
    if (debug) {
        console.debug("loading featured people");
    }

    // adjustColorOverlay();
    // check breakpoint
    breakpoint = breakpoints.find();

    // handle multiple featured people components on a page
    $(featuredPeopleSelector).each(function(index, el) {
        let $el = $(el);
        if (breakpoint === "small" && !ee.editing) {
            slick($el);
            setTimeout(matchHeight, 10000);

        } else {
            $el.children().each(function(index, element) {
                setWaypoint($(element));
                setTimeout(matchHeight, 10000);
            });
        }
    });
}

function reset() {
    if (debug) {
        console.debug("resetting featured people");
    }
    $(featuredPeopleSelector).each(function(index, el) {
        let $el = $(el);
        // if slicked, unslick
        if ($el.hasClass(".slick-initialized")) {
            $el.slick("unslick");
        }
        // remove any animated classes
        $el.find(".animated").each(function(index, element) {
            $(element).removeClass("animated");
        });
    });

    init();
}

function slick($el) {
    $el.slick({
        accessibility: true,
        speed: 700,
        easing: "swing",
        centerMode: false,
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        dots: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: breakpoints.medium,
                settings: "unslick"
            }
        ]
    });
}

function setWaypoint($el) {
    let waypoint = new Waypoint({
        element: $el,
        handler: function() {
            if (debug) {
                console.debug("waypoint hit");
            }
            animate($el);
            waypoint.disable(); // only want to trigger each waypoint once
        },
        offset: "100%" // trigger when top of element hits bottom of viewport
    });
}

function animate($el) {
    $el.find(".js-featured-person-overlay").addClass("animated");
}
// force height with new object height cover to not go too tall, especially in IE11
function matchHeight() {
    if (debug) {
        console.log("run MatchHeight");
    }
    const boundryDiv = ".featured-person__content-boundry";
    $(".js-featured-person-slide").each(function() {
        let $this = $(this);

        if (breakpoint !== "small") {
            let maxHeight = $this.find(boundryDiv).outerHeight();
            $this.css("min-height", maxHeight);
            if (debug) {
                console.log("doesn't have class", {maxHeight});
            }
        }
    });
}

//adjust height of color overlay on tablets
// function adjustColorOverlay(){
//     $('.js-featured-person-slide').each(function(i, value){
//         var imgHeight = $(this).find('.featured-person__image').height();
//         $('.js-featured-person-overlay', this).css('height', imgHeight);
//     });
// }
// $(window).on('resize', adjustColorOverlay);
