import R from "ramda";
import React from "react";
import classnames from "classnames";

function activeSortAscClass(sorter) {
  return classnames({ "is-selected": sorter.isActive && sorter.direction === "asc"});
}

function activeSortDescClass(sorter) {
  return classnames({ "is-selected": sorter.isActive && sorter.direction === "desc"});
}

function activeSortWithEnter(e, sorterId, sorters, handleSortClick) {
  if (e.keyCode === 13) {
    activateOrToggleSorter(sorterId, sorters, handleSortClick);
  }
}

function activateOrToggleSorter(sorterId, sorters, handleSortClick) {
  const toggleDir = dir => {
    if (!dir) {
      return null;
    } else {
      return  dir === "asc" ? "desc" : "asc";
    }
  };

  // this is like a 'bookmark' to the direction property
  const dirLens = R.lensProp("direction");

  // each argument array supplies a condition predicate, and an action
  // 1) if clicked sorter and active, toggle the sort direction
  // 2) if clicked sorter and not active, make active
  // 3) if not clicked sorter, and active, make inactive
  // 4) all other cases, do nothing
  const adjustSorters = R.cond([
    [R.whereEq({id: sorterId, isActive: true}), R.over(dirLens, toggleDir)],
    [R.whereEq({id: sorterId, isActive: false}), R.assoc("isActive", true)],
    [R.whereEq({isActive: true}), R.assoc("isActive", false)],
    [R.T, R.identity]
  ]);

  const newSorters = R.map(adjustSorters, sorters);
  handleSortClick(newSorters);
}

const SortBar = ({sorters, dictionary, handleSortClick}) => {
  return (
    <div className="search-results__sort-bar">
      <span className="search-results__sort-by">{dictionary.sortByLabel}</span>
      <ul className="search-results__sort-options">
        {sorters.map(sorter => {
          let sorterClass = classnames("search-results__sorter", { "is-active": sorter.isActive });
          return (
            <li
                key={sorter.id}
                className={sorterClass}
                tabIndex="0"
                role="button"
                onKeyUp={ (e) => activeSortWithEnter(e, sorter.id, sorters, handleSortClick) }
                onClick={ () => activateOrToggleSorter(sorter.id, sorters, handleSortClick) }>
              <span className="search-results__sort-label">{sorter.label}</span>
              {sorter.direction && (
                <span className="search-results__sort-dir">
                  <button tabIndex="-1" type="button" className={activeSortAscClass(sorter)}>
                  <span className="screen-reader-only">{sorter.label}</span>
                    <svg>
                      <use xlinkHref="/assets/build/img/svg-sprite.svg#arrow_drop_up"></use>
                    </svg>
                  </button>
                <button tabIndex="-1" type="button" className={activeSortDescClass(sorter)}>
                  <span className="screen-reader-only">{sorter.label}</span>
                  <svg>
                    <use xlinkHref="/assets/build/img/svg-sprite.svg#arrow_drop_down"></use>
                  </svg>
                  </button>
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const { shape, func, number, string, bool, oneOfType, arrayOf } = React.PropTypes;

SortBar.propTypes = {
  sorters: arrayOf(shape({
    id: oneOfType([number, string]).isRequired,
    label: string.isRequired,
    value: string.isRequired,
    direction: oneOfType([bool, string]),
    isActive: bool.isRequired
  })).isRequired,
  dictionary: shape({
    sortByLabel: string
  }),
  handleSortClick: func.isRequired
};

export default SortBar;
