import $ from "jquery";
window.$ = $;
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import ee from "./helpers/experience-editor.js";

const   debug = false,
        largeCarouselSelector = ".js-large-carousel-slider";

export default {
    init: initLargeCarouselSlider,
    selector: largeCarouselSelector
}

function initLargeCarouselSlider() {
    $(function(){
        var $largeCarouselEls = $(largeCarouselSelector),
            onLoadPageHeight = $(window).scrollTop() + window.innerHeight,
            largeCarouselTimeout = null;

        var fadeCarouselContent = function(windowPosition) {
            $.each($largeCarouselEls, function(i, item) {
                var $carouselEl = $(item);

                // check if item is in view & if so, fade it in
                if ($carouselEl.offset().top < windowPosition) {
                    $carouselEl.addClass("large-carousel--show");
                }
            });
        };

        var scrollHandler = function() {
            fadeCarouselContent($(window).scrollTop() + window.innerHeight);
        };

        if (!ee.editing){
            $largeCarouselEls.slick({
                speed: 700,
                easing: "swing",
                slidesToShow: 1,
                accessibility: true,
                arrows: true,
                centerMode: false
            });
        }

        fadeCarouselContent(onLoadPageHeight);

        $(window).scroll(function() {
            if (largeCarouselTimeout) {
                clearTimeout(largeCarouselTimeout);
            }
            largeCarouselTimeout = setTimeout(scrollHandler, 100);
        });
    });
}

