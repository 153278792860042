import $ from "jquery";
import waypoints from "./helpers/jquery.waypoints.js";

const debug = false,
      selector = ".js-statistic",
      countTimeInMs = 1500,
      incrementTimeInMs = 20;

export default {
    selector: selector,
    init: init
};


function init() {
  // global init
  let cultureCode = $(".js-culture-code").data("cultureCode");
  if(debug) { console.debug("culture code = " + cultureCode); }

  let cultureInfo = getCultureInfoFromCode(cultureCode);

  // each statistic init
  $(selector).each( (index, el) => initStatistic($(el), cultureInfo) );
}

function initStatistic($element, cultureInfo) {
    let statisticInfo = getStatisticInfo($element, cultureInfo);

    if (statisticInfo.parsedStatisticText.isNumber) {
        setWaypoint(statisticInfo.$element, () => increment(statisticInfo));
    } else {
        updateDom(statisticInfo.parsedStatisticText.original, statisticInfo.$element);
    }
}

function increment(statisticInfo) {
    statisticInfo.curCount += statisticInfo.timingInfo.incrementAmount;

    if (statisticInfo.curCount >= statisticInfo.parsedStatisticText.typedNumber) {
    // end condition
        statisticInfo.curCount = statisticInfo.parsedStatisticText.typedNumber;
    } else {
    // keep incrementing
    setTimeout(function() {
       increment(statisticInfo);
    }, statisticInfo.timingInfo.incrementTimeInMs);
  }

  displayNumber(statisticInfo);
}

function displayNumber(statisticInfo) {

  let roundedNum = statisticInfo.parsedStatisticText.isInt
    ? Math.floor(statisticInfo.curCount)
    : Number(statisticInfo.curCount.toFixed(statisticInfo.parsedStatisticText.decimalPlaces));

  let localizationOptions = {
      useGrouping: statisticInfo.parsedStatisticText.useGroupSeparator,
      maximumFractionDigits: 6,
      minimumFractionDigits: statisticInfo.parsedStatisticText.decimalPlaces 
  };

  let localizedNumberString = roundedNum.toLocaleString(statisticInfo.cultureInfo.cultureCode, localizationOptions);

  let recombinedString = statisticInfo.parsedStatisticText.prefix + localizedNumberString + statisticInfo.parsedStatisticText.postfix;

  updateDom(recombinedString, statisticInfo.$element);
}

function updateDom(text, $element) {
    $element.text(text);
}

function setWaypoint($element, action){
    let waypoint = new Waypoint({
        element: $element,
        handler: function(direction){
            if (direction === "down"){
                action();
                waypoint.disable(); // only want to trigger each waypoint once
            }
        },
        offset: "bottom-in-view"
    });
}

function getStatisticInfo($element, cultureInfo) {

    let statisticText = $element.data("number").toString();
    let parsedStatisticText = parseStatisticText(statisticText, cultureInfo);
    let timingInfo = parsedStatisticText.isNumber
        ? getTimingInfo(parsedStatisticText.typedNumber)
        : null;

    return {
        $element,
        cultureInfo,
        parsedStatisticText,
        timingInfo,
        curCount: 0
    };

    function parseStatisticText(statisticText, cultureInfo) {

        let nonNumberResult = { original: statisticText, isNumber: false };

        let regexResult = cultureInfo.cultureRegex.exec(statisticText);
        if(regexResult == null) { return nonNumberResult; }

        let typedNumber = castToNumber(regexResult[2], cultureInfo);

        if(!typedNumber) { return nonNumberResult; }

        // figure out a bunch of stuff about the #
        let decimalPart = regexResult[4];
        let isInt = !decimalPart || decimalPart.length === 0;
        let decimalPlaces = isInt ? 0 : decimalPart.length - 1;

        let useGroupSeparator = statisticText.indexOf(cultureInfo.groupSeparator) > -1;

        return {
            original: statisticText,
            isNumber: true,
            prefix: regexResult[1],
            typedNumber: typedNumber,
            decimalPart: decimalPart,
            postfix: regexResult[5],
            decimalPlaces: decimalPlaces,
            isInt: isInt,
            useGroupSeparator: useGroupSeparator
        };

        function castToNumber(numberString, cultureInfo) {
            let groupRegex = new RegExp("\\" + cultureInfo.groupSeparator, "g");
            let normalizedString = numberString.replace(groupRegex, "");
            normalizedString = normalizedString.replace(cultureInfo.decimalSeparator, ".");

            return Number(normalizedString);
        }
    }

    function getTimingInfo(targetNumber) {
        var incrementAmount = targetNumber / (countTimeInMs / incrementTimeInMs);
        return {
            incrementAmount,
            countTimeInMs,
            incrementTimeInMs
        };
    }

}

function getCultureInfoFromCode(cultureCode) {

    let separators = getCultureSeparators(cultureCode);

    return {
        cultureCode: cultureCode,
        groupSeparator: separators.group,
        decimalSeparator: separators.decimal,
        cultureRegex: buildRegex(separators)
    };

    function getCultureSeparators(cultureCode) {
        var testNum = 1000.1; // Don't change this, it's hardcoded on purpose
        var localizedNum = testNum.toLocaleString(cultureCode);

        var groupIndex = 1;
        var decimalIndex = localizedNum.length - 2;

        var group = localizedNum[groupIndex];
        group = group != 0 ? group : ",";

        var decimal = localizedNum[decimalIndex];
        decimal = decimal != 0 ? decimal : ".";

        if(debug){ console.debug( "group separator is " + group, "decimal separator is " + decimal);}

        return {
            group,
            decimal
        };
    }

    function buildRegex(separators) {
        let pattern =    "^(.*?)((\\d{1,3}\\"
                     + separators.group
                     + "?)+(\\"
                     + separators.decimal
                     + "\\d+)?)(.*)$";
        // regex pattern: "^(.*?)((\\d{1,3}\\,?)+(\\.\\d+)?)(.*)$"

        return new RegExp(pattern);
    }
}