import $ from "jquery";
window.$ = $;
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import breakpoints from "./helpers/breakpoints.js";
import ee from "./helpers/experience-editor.js";

const   debug = false,
        teaserBlocksSelector = ".js-teaser-slider",
        teaser3UpClass = "js-3-teaser-blocks",
        teaser2UpClass = "js-2-teaser-blocks";

var breakpoint;

export default {
    init: init,
    selector: teaserBlocksSelector
}

function init() {
    if(debug){ console.debug("loading teaser blocks"); }

    // check breakpoint
    breakpoint = breakpoints.find();

    // handle multiple related experts components on a page
    $(teaserBlocksSelector).each(function(index, el){
        let $el = $(el);

        if ($el.hasClass(teaser3UpClass)){
            if(debug){ console.debug("teaser block component is 3-up"); }

            if (!(breakpoint === "large") && !ee.editing){
                slick3up($el);
            }
        } else if ($el.hasClass(teaser2UpClass)) {
            if(debug){ console.debug("teaser block component is 2-up"); }

            if (breakpoint === "small" && !ee.editing){
                slick2up($el);
            }
        }
    });

    bindHandlers();
}

function slick2up($el) {
    if(debug){ console.debug("slicking 2-up teaser blocks"); }

    // unslick if already slicked
    if ($el.hasClass(".slick-initialized")) {
        $el.slick("unslick");
    }

    $el.slick({
        accessibility: true,
        speed: 700,
        easing: "swing",
        centerMode: false,
        centerPadding: "25px",
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: breakpoints.medium,
                settings: "unslick"
            }
        ]
    });
}

function slick3up($el) {
    if(debug){ console.debug("slicking 3-up teaser blocks"); }

    // unslick if already slicked
    if ($el.hasClass(".slick-initialized")) {
        $el.slick("unslick");
    }

    $el.slick({
        accessibility: true,
        speed: 700,
        easing: "swing",
        centerMode: true,
        centerPadding: "25px",
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: breakpoints.medium,
                settings: {
                    centerPadding: "75px"
                }
            },
            {
                breakpoint: breakpoints.large,
                settings: "unslick"
            }
        ]
    });
}

function preventClick(e) {
    let $block = $(e.target.closest(".slick-slide"));

    if ($block.hasClass("slick-slide") && !($block.hasClass("slick-current"))) {
        e.preventDefault();
    }
}

function bindHandlers() {
    $(function(){

        $(".teaser-block__link").on("click", function(e) {
            preventClick(e);
        });

    });
}