import $ from "jquery";
import debounce from "./helpers/debounce.js";

const   debug = false,
        accommodationRequestFormSelector = ".js-accommodation-request-form",
        $form = $(accommodationRequestFormSelector),
        $submitBtn = $(".js-accommodation-request-form-submit"),
        $cancelBtn = $(".js-accommodation-request-form-cancel"),
        $firstNameField = $(".js-accommodation-request-form-firstname"),
        $lastNameField = $(".js-accommodation-request-form-lastname"),
        $emailField = $(".js-accommodation-request-form-email"),
        $telephoneField = $(".js-accommodation-request-form-telephone"),
        $reqJobNumField = $(".js-accommodation-request-form-reqjobnum"),
        $accomRequestDetailsField = $(".js-accommodation-request-form-accomrequestdetails"),
        $formMessages = $(".js-accommodation-request-form-message"),
        $reCaptchaField = $(".email-form__captcha--invisible"),
        $successPage = $('#successpage');

export default {
init : init,
    selector: accommodationRequestFormSelector,
bindHandlers: bindHandlers
};

function init() {
    if(debug) { console.debug("loading accommodation request form") }
    bindHandlers();
};

//function disableSubmit() {
//    if (!$submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", true);
//    }
//}

//function enableSubmit() {
//    if ($submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", false);
//    }
//}

function disableSubmit() {
    if (!$submitBtn.hasClass("disabled")) {
        $submitBtn.addClass("disabled");
    }
}

function enableSubmit() {
    if ($submitBtn.hasClass("disabled")) {
        $submitBtn.removeClass("disabled");
    }
}

function formIsValid() {
    return $form.get(0).checkValidity();
}

function checkFormValidity() {
    if (formIsValid()) {
        enableSubmit();
    } else {
        disableSubmit();
    }
}

function checkInputValidity($inputField, clearFieldsWithErrorsOnly) {
    if (!$inputField.get(0).validity.valueMissing) {
        $inputField.parent().removeClass('error');
        $('.message-li-' + $inputField.attr('name')).removeClass('active-error');
    } else {
        if (!clearFieldsWithErrorsOnly) {
            $inputField.parent().addClass('error');
            $('.message-li-' + $inputField.attr('name')).addClass('active-error');
        }
    }
}

function checkInputFields(clearFieldsWithErrorsOnly) {
    checkInputValidity($firstNameField, clearFieldsWithErrorsOnly);
    checkInputValidity($lastNameField, clearFieldsWithErrorsOnly);
    checkInputValidity($telephoneField, clearFieldsWithErrorsOnly);
    checkInputValidity($reqJobNumField, clearFieldsWithErrorsOnly);
    checkInputValidity($accomRequestDetailsField, clearFieldsWithErrorsOnly);
}

function checkEmailValidity() {
    if (!$emailField.get(0).validity.valueMissing) {
        if (!$emailField.get(0).validity.valid) {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().addClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).addClass('active-error');
            //showMessage();
        } else {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().removeClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
            //hideMessage();
        }
    } else {
        $emailField.parent().removeClass('email-error');
        $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
        $emailField.parent().addClass('error');
        $('.message-li-' + $emailField.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

//function checkTelephoneValidity() {
//    if (!$telephoneField.get(0).validity.valueMissing) {
//        if (!$telephoneField.get(0).validity.valid) {
//            $telephoneField.parent().removeClass('error');
//            $('.message-li-' + $telephoneField.attr('name')).removeClass('active-error');
//            $telephoneField.parent().addClass('email-error');
//            $('.message-li-invalid-' + $telephoneField.attr('name')).addClass('active-error');
//            //showMessage();
//        } else {
//            $telephoneField.parent().removeClass('error');
//            $('.message-li-' + $telephoneField.attr('name')).removeClass('active-error');
//            $telephoneField.parent().removeClass('email-error');
//            $('.message-li-invalid-' + $telephoneField.attr('name')).removeClass('active-error');
//            //hideMessage();
//        }
//    } else {
//        $telephoneField.parent().removeClass('email-error');
//        $('.message-li-invalid-' + $telephoneField.attr('name')).removeClass('active-error');
//        $telephoneField.parent().addClass('error');
//        $('.message-li-' + $telephoneField.attr('name')).addClass('active-error');
//        //hideMessage();
//    }
//}

function checkReCaptchaValidity() {
    if (!$reCaptchaField.get(0).validity.valueMissing) {
        $('.message-li-' + $reCaptchaField.attr('name')).removeClass('active-error');
        //hideMessage();
    } else {
        $('.message-li-' + $reCaptchaField.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

function submitForm() {
    let formData = $form.serialize();

    // add recaptcha if it exists in the form
    var captchaResponse = grecaptcha.getResponse();
    if (captchaResponse !== undefined) {
        formData += "&recaptcharesponse=" + encodeURIComponent(captchaResponse);
    }

    $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: formData
    })
		.done(function(success) {
		    if (!success) {
		        return emailFail();
		    }

		    //$formMessages.removeClass('error email-error');
		    //$formMessages.addClass('success');
		    //showMessage();

		    disableSubmit(); // prevent resubmission
            window.location.replace($successPage.val());
		})
		.fail(emailFail);
}

function emailFail() {
    //$formMessages.removeClass('success email-error');
    //$formMessages.addClass('error');
    //showMessage();
    // reset captcha if available
    grecaptcha.reset();
}

function showMessage() {
    $formMessages.addClass('active-error');
    $formMessages.attr("aria-hidden", "false");
}

function hideMessage() {
    $formMessages.removeClass('active-error');
    $formMessages.attr("aria-hidden", "true");
}

function bindHandlers(){
    // note: won't trigger if autocomplete (triggered from a different field) fills in an incorrect email format
    $emailField.on("blur", debounce(function() {
        checkEmailValidity();
        checkFormValidity();
    }, 250));

    $firstNameField.on("blur", debounce(function() {
        checkInputValidity($firstNameField, false);
        checkFormValidity();
    }, 250));

    $lastNameField.on("blur", debounce(function() {
        checkInputValidity($lastNameField, false);
        checkFormValidity();
    }, 250));

    $telephoneField.on("blur", debounce(function() {
        checkInputValidity($telephoneField, false);
        checkFormValidity();
    }, 250));

    $reqJobNumField.on("blur", debounce(function() {
        checkInputValidity($reqJobNumField, false);
        checkFormValidity();
    }, 250));

    $accomRequestDetailsField.on("blur", debounce(function() {
        checkInputValidity($accomRequestDetailsField, false);
        checkFormValidity();
    }, 250));

    $form.on("keyup change", "input[required], textarea[required], select[required]", debounce(function() {
        checkInputFields(true);
        checkFormValidity();
    }, 250));

    $cancelBtn.on("click", function() {
        $(this).closest('form').find("input[type=text], input[type=email], textarea").val("");
        $(this).closest('form').find("input[type=text], input[type=email], textarea").parent().removeClass('error');
        $(this).closest('form').find("input[type=text], input[type=email], textarea").parent().removeClass('email-error');
        hideMessage();
        grecaptcha.reset();
        checkFormValidity();
    });

    $form.on("submit", function(e) {
        e.preventDefault();
        checkInputFields(false);
        checkEmailValidity();
        checkFormValidity();
        checkReCaptchaValidity();

        if (formIsValid()) {
            submitForm();
            hideMessage();
            return true; // completes form submit action
        } else {
            showMessage();
            $('.error').filter(":first").trigger("focus");
            return false;
        }
    });
}
