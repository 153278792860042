import React from "react";

export default class ResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  getFormatString(lang) {
    switch (lang) {
      case 'de':
        return 'LL';
      case 'pl':
        return 'LL';
      case 'es':
        return 'D MMMM YYYY';
      case 'en-ME':
        return 'D MMMM YYYY';
      case 'en-EU':
        return 'D MMMM YYYY';
      case 'en-AU':
        return 'LL';
      default:
        return 'LL';
    }
  }

  formatDate(dateStr) {
    const timestamp = Date.parse(dateStr);

    if (isNaN(timestamp)) {
      return dateStr;
    } else {
      let theDate = moment(new Date(dateStr));

      theDate.locale(this.props.language);

      return theDate.format(this.getFormatString(this.props.language));
    }
  }

  render() {
    const { title, summary, contentType, date, byline, url, imageUrl } = this.props;
    const backgroundImage = { backgroundImage: 'url(' + imageUrl + ')' };

    return (
      <article className="search-item" ref={el => this.itemWrapper = el}>
        {imageUrl && (
          <a href={url} className="search-item__image-link">
            <div className="search-item__image-container" style={backgroundImage}>
              <span className="screen-reader-only">{title}</span>
            </div>
          </a>
        )}
        <div className="search-item__content">
          {contentType ?
            <div className="search-item__meta">
              <span className="search-item__content-type">{contentType}</span>
            </div>
            : null
          }
          <h3 className="search-item__title"><a href={url}>{title}</a></h3>
          {byline ?
            <h4 className="search-item__subtitle">
              <span className="search-item__authors">{this.formatDate(byline)}</span>
            </h4>
            : null
          }
          <p className="search-item__body">{summary}</p>
        </div>
      </article>
    );
  }
}

const { arrayOf, string } = React.PropTypes;
ResultItem.propTypes = {
  contentType: string,
  date: string.isRequired,
  byline: string,
  title: string.isRequired,
  summary: string.isRequired,
  url: string.isRequired,
  imageUrl: string
};
