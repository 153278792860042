import $ from "jquery";
import breakpoints from "./helpers/breakpoints.js";
import debounce from "./helpers/debounce.js";
import restrictFocus from "./helpers/trap-focus.js";

const debug = false,
    $mobileNavDrawer = $(".js-mobile-nav-drawer");

var isMobile,
    navDrawerFocusables = "",
    lastFocus,
    modalIsOpen = false,
    focusTimeable = setTimeout(() => {}, 1);

export default {
    init : init(),
    hideNav : hideMobileNavPanel
};

function init() {
    isMobile = checkIfMobile();
    if (debug) {
        console.debug("mobile nav view? " + isMobile);
    }

    // if mobile, make closed nav drawer unfocusable
    if (isMobile) {
        navDrawerFocusables = $mobileNavDrawer.find("a, button, input");
        hideMobileNavPanel();
    }

    bindEventHandlers();
}

function checkIfMobile() {
    return breakpoints.find() === "small";
}

function expandDropdown($item) {
    $item.addClass("nav__item--open");
    $item.find(".js-toggle-dropdown").attr("aria-expanded", "true");
    $item.find(".nav__dropdown").attr("aria-hidden", "false");
}

function collapseDropdown($item) {
    $item.removeClass('nav__item--open');
    $item.find(".js-toggle-dropdown").attr("aria-expanded", "false");
    $item.find(".nav__dropdown").attr("aria-hidden", "true");
}

function collapseAllDropdowns() {
    $(".nav__item--open").removeClass('nav__item--open');
    $(".js-toggle-dropdown").attr("aria-expanded", "false");
    $(".nav__dropdown").attr("aria-hidden", "true");
}

function toggleMobileNavItem($item) {
    $item.toggleClass("nav__item--open");
}

function setKeyboardTrap() {
    return restrictFocus.restrictFocus(event, $mobileNavDrawer);
}

function closeWithEscape(event) {
  clearTimeout(focusTimeable);
    if (event.keyCode === 27) { // esc key
        if (debug) {
            console.debug("ESC pressed")
        }
        hideMobileNavPanel();
       focusTimeable =  setTimeout(() => {
       $(".js-nav-toggle").trigger("focus");
      }, 200);
    }
}

function revealMobileNavPanel() {
    if (debug) {
        console.debug("opening mobile nav panel");
    }
    $("body").removeClass("is-nav-closed").addClass("is-nav-open");

    if (navDrawerFocusables !== "") {
        navDrawerFocusables.each(function(index, el) {
            $(el).attr("tabindex", "0");
        });
    }

    $mobileNavDrawer.attr("aria-hidden", "false");
    $mobileNavDrawer.attr("tabindex", "0");
    modalIsOpen = true;
    $("body").addClass("modal-is-open");
    lastFocus = document.activeElement; // save last element focused
    $(".nav__wrapper .logo-link").trigger("focus"); // send focus to first focusable element
    document.addEventListener("focus", setKeyboardTrap, true); // set keyboard trap
    document.addEventListener("keydown", closeWithEscape, true); // listen for ESC
}

function hideMobileNavPanel() {
    if (debug) {
        console.debug("closing mobile nav panel");
    }
    $("body").removeClass("is-nav-open").addClass("is-nav-closed");

    if (navDrawerFocusables !== "") {
        navDrawerFocusables.each(function(index, el) {
            $(el).attr("tabindex", "-1");
        });
    }

    $("body").removeClass("modal-is-open");
    $mobileNavDrawer.attr("aria-hidden", "true");
    $mobileNavDrawer.attr("tabindex", "-1");
    modalIsOpen = false;
    collapseAllDropdowns();
}

function bindEventHandlers() {
    $(function() {

        // Toggle expand of dropdown menu on click
        $(".js-toggle-dropdown").on("click", function() {
            let $parent = $(this).parent();
            let isParentOpen = $parent.hasClass('nav__item--open');

            if (isParentOpen) {
                collapseDropdown($parent);
            } else {
                if (!isMobile) {
                    collapseAllDropdowns();
                }
                expandDropdown($parent);
            }
        });

        $("body").on("click", function(e) {
            // check if nav is open
            if ($(".modal-is-open").length) {
                var modal = $(e.target).parents(".js-mobile-nav-drawer");
                var cookie = $(e.target).parents(".cookie-warning__button-wrapper");
                if(modal.length == 0 && cookie.length == 0){
                    hideMobileNavPanel();
                }
            }
        });


        // reveal & hide mobile nav panel
        $(".js-nav-toggle").on("click", function(e) {
            revealMobileNavPanel();
            return false;
        });

        $(".js-nav-close").on("click", function(e) {
            hideMobileNavPanel();
            focusTimeable =  setTimeout(() => {
            $(".js-nav-toggle").trigger("focus");
          }, 200);
            return false;
        });

        // close mobile nav panel if a button is clicked
        if (isMobile) {
            $(".js-open-region-takeover").on("click", function(e) {
                hideMobileNavPanel();
            });

            $(".js-contrast-toggle").on("click", function(e) {
                hideMobileNavPanel();
            });
        }
    });

    $(window).on("resize", debounce(function() {
        // recheck if mobile
        isMobile = checkIfMobile();
        if (debug) {
            console.debug(isMobile);
        }
    }, 250));
}
