export default function toggleNav(window, document, $, undefined) {

    var visibleThreshold = 250;
    var scrollTop;

    $(".js-back-to-top").each(function() {
        var $el = $(this);

        $el.on("click",function(e) {
            e.preventDefault();
            $("html, body").animate({scrollTop: "0px"}, 750);
            window.activeElement = $('body');
        });

        $(window).on("scroll", function() {
            // if we've exceeded the threshold of scrolling
            // from the top, show control
            scrollTop = $(window).scrollTop();

            if (scrollTop > visibleThreshold) {
                $el.removeClass("is-hidden");
            } else {
                $el.addClass("is-hidden");
            }
        });
    });

}(window, document, jQuery);
