import $ from "jquery";
const tabClass = ".js-cookie-tabs";
const $tabContainer = $(tabClass);
const $tabs = $(".js-cookie-tab");
const $tabContent = $(".js-cookie-tab-content");
const $cookieForm = $(".cookie-warning__form-container");
const cookieSelector = "#js-cookie-warning";
const $tabButton = $(".js-cookie__display-tabs");
const $submitAll = $(".js-cookie-warning__button--submit-all");
const $submitSelected = $(".js-cookie-warning__button--submit");
const $selectAll = $(".js-cookie-warning__button--select-all");
const submitAllButtonClass = ".js-cookie-warning__button--submit-all";
const submitButtonClass = ".js-cookie-warning__button--submit";
export default {init: cookieWarningTabs, selector: tabClass}

function cookieWarningTabs() {
    openInitialTab();
    bindEvents();

}
function bindEvents() {
    $tabs.on("click", toggleTab);

    $tabButton.on("click", toggleTabUi);
}
function toggleTabUi() {
    $cookieForm.slideToggle();
    if ($cookieForm.length > 0) {
        $tabButton.hide();
        $submitAll.hide();
        $selectAll.show();
        $submitSelected.show();
        setFocusTrap(submitButtonClass);
    } else {
        $tabButton.show();
        $submitAll.show();
        $submitSelected.hide();
        $selectAll.hide();
        setFocusTrap(submitAllButtonClass);
    }
}
function openInitialTab() {
    $tabs.first().addClass('is-active');
    $tabContent.first().addClass('is-visible');
}

function toggleTab(event) {
    event.preventDefault();
    const windowWidth = $(window).width();
    const $activeTab = $(this);
    const $activeIndex = $tabs.index($activeTab);
    const $activeContent = $tabContent.eq($activeIndex);

    $tabs.removeClass("is-active");
    $activeTab.addClass("is-active");

    $tabContent.removeClass("is-visible");
    $activeContent.addClass("is-visible");

    if (windowWidth <= 720) {
        $tabContainer.toggleClass("is-open");
    }
}

function setFocusTrap(lastElement) {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(cookieSelector); // select the modal by it's id

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = modal.querySelector(lastElement);//focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    document.addEventListener('keydown', function (e) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus(); // add focus for the last focusable element
                e.preventDefault();
            }
        } else { // if tab key is pressed
            if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstFocusableElement.focus(); // add focus for the first focusable element
                e.preventDefault();
            }
        }
    });

    firstFocusableElement.focus();
}
