import React from "react";
import classnames from "classnames";
import { isLastPage } from "../paging";
import { openFilters } from "../filterSlider";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SortBar from "./SortBar.jsx";
import SearchStatus from "./SearchStatus.jsx";
import Pagination from "./Pagination.jsx";
import {RESULTS_DISPLAY_REFRESH, RESULTS_DISPLAY_APPEND} from "../redux/resultsDisplay";

const ResultList = ({
  results,
  resultsDisplayStrategy,
  selectedFacets,
  totalResults,
  loading,
  isFirstLoad,
  sorters,
  nextPage,
  gotoPage,
  dismissFacet,
  changeSort,
  query,
  pagination,
  showStatus,
  dictionary
}) => {


  const isLast = isLastPage(totalResults, query.perpage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perpage;

  function showFilters() {
    openFilters();
  }

  function wrapperClass() {
    const isRefreshing = loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending = loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", { "is-refreshing": isRefreshing, "is-appending": isAppending });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = (query.page * query.perpage );

    // Lower bound of visible results
    let floor = (ceiling - query.perpage) + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perpage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    // if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
    //   return `${ceiling}`;
    // }

    // otherwise, return the full range
    return `${ceiling}`;
  }


  function renderPagingControl() {
    if (pagination) {
      return (
        <Pagination
          totalResults={totalResults}
          resultsPerPage={query.perpage}
          currentPage={query.page}
          handlePageChange={gotoPage} />
      );
    }

    if (!pagination && !isLast) {
      return <LoadMore handleClick={nextPage} dictionary={dictionary} loading={loading} />;
    }
  }

  return (
    <section className={wrapperClass()}>
      {
        showStatus || sorters.length ?
        <div className="search-results__status-sort">
        {
          showStatus ?
          <SearchStatus
            resultsRange={resultsRange()}
            totalResults={totalResults}
            keywords={query.q}
            selectedFacets={selectedFacets}
            handleFacetDismiss={dismissFacet}
            dictionary={dictionary}/>
          : null
        }
        { sorters.length ? <SortBar sorters={sorters} dictionary={dictionary} handleSortClick={changeSort}/> : null }
      </div>
      : null
      }
      <div className="search-results__item-wrapper">
        <div id="search-results" className="search-results__items" aria-live="polite">
          {
            shouldShowNoResults ?  (
                <p>{dictionary.noResults}</p>
              ) : null
          }
          { results.map( (item) => <ResultItem key={item.id} {...item} />) }
        </div>
      </div>
      { shouldShowPager ? renderPagingControl() : null }
    </section>
  );
};

const { arrayOf, string, object, func, bool, number, shape, oneOfType } = React.PropTypes;

ResultList.propTypes = {
  results: arrayOf(shape({
    id: oneOfType([number, string])
  })),
  resultsDisplayStrategy: number,
  selectedFacets: arrayOf(object).isRequired,
  totalResults: number.isRequired,
  sorters: arrayOf(shape({
    id: oneOfType([number, string]).isRequired,
    label: string.isRequired,
    value: string.isRequired,
    direction: oneOfType([bool, string]),
    isActive: bool.isRequired
  })),
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  dismissFacet: func,
  query: shape({
    q: string,
    sortby: string,
    page: number,
    perpage: number,
    pageid: string
  }),
  pagination: bool,
  dictionary: object
};

ResultList.defaultProps = {
  selectedFacets: []
};

export default ResultList;
