import React from "react";
import R from "ramda";
import classnames from "classnames";
import {
  valuesWithSelectedChildren
} from "../redux/facets";

const MAX_FACETS_SHOWN = 5;

export default class facetTag extends React.Component {
  constructor(props) {
    super(props);
  }

  handleTagClick(event, clickedFacet) {
    this.props.updateSelectedFacets(clickedFacet, false);
    this.props.handleFacetChange(clickedFacet.id, false);
  }

  handleTagEnter(event, clickedFacet) {
    if (event.keyCode === 13) {
      handleTagClick(event, clickedFacet);
    }
  }

  render() {
    const facet = this.props.facet;

    return (
      <div className="search-tags__tag" 
        tabIndex="0"
        role="button"
        onKeyUp={ (e) => this.handleTagEnter(e, facet)}
        onClick={(e) => this.handleTagClick(e, facet)}>
        {facet.name}
      </div>
    );
  }
}

const { func, shape, bool, number, string, oneOfType, arrayOf } = React.PropTypes;

// allows us to define propTypes recursively
// https://github.com/facebook/react/issues/5676
const lazyF = f => ((...args) => f().apply(this, args));

const facetTagShape = shape({
  id: oneOfType([number, string]).isRequired,
  name: string.isRequired,
  count: number.isRequired,
  selected: bool.isRequired,
  childValues: arrayOf(lazyF( () => facetTagShape ))
});

facetTag.propTypes = {
  isOpen: bool,
  handleFacetChange: func.isRequired,
  updateSelectedFacets: func.isRequired,
  facet: facetTagShape
};

