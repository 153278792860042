import R from "ramda";
import { mapResponseToState } from "../service";
import {
  setSelectedOnFacetValues,
  selectedFacetsFromGroup
} from "../facets";

import {
  RESULTS_DISPLAY_APPEND,
  RESULTS_DISPLAY_REFRESH
} from "../resultsDisplay";

import {
  LOAD_RESULTS_START,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAIL,
  MERGE_QUERY_PARAMS,
  SORT_CHANGED,
  KEYWORDS_CHANGED,
  INCREMENT_PAGE,
  CHANGE_PAGE,
  FACET_CHANGED,
  CLEAR_FACET_GROUP,
  CLEAR_ALL_FACETS
} from "./search.actions";

export const initialState = {
  query: {
    q: null,
    sortBy: "date",
    sortOrder: "desc",
    page: 1,
    perPage: 10,
    pageId: "00000000-0000-0000-0000-000000000000"
  },
  url: null,
  errorMsg: null,
  loading: true,
  pagination: false,
  totalResults: 0,
  results: [],
  resultsDisplayStrategy: RESULTS_DISPLAY_APPEND,
  sorters: [],
  dictionary: {},
  facets: [],
  numOpenGroups: null,
  preSelectedFacets: {},
  isFirstLoad: true
};


const actionMap = {
  [LOAD_RESULTS_START]: loadStart,
  [LOAD_RESULTS_SUCCESS]: loadSuccess,
  [LOAD_RESULTS_FAIL]: loadError,
  [SORT_CHANGED]: updateSort,
  [MERGE_QUERY_PARAMS]: updateQueryParams,
  [KEYWORDS_CHANGED]: updateKeywords,
  [INCREMENT_PAGE]: incrementPage,
  [CHANGE_PAGE]: changePage,
  [FACET_CHANGED]: changeFacet,
  [CLEAR_FACET_GROUP]: clearFacetGroup,
  [CLEAR_ALL_FACETS]: clearAllFacets
};

export default function searchReducer(state = initialState, action) {
  const actionType = actionMap[action.type];
  return actionType ? actionType(state, action) : state;
}

function loadStart(state, action) {
  return R.merge(state, { loading: true });
}

function loadSuccess(state, action) {
  const { response } = action.payload;
  return R.merge(
    mapResponseToState(response, state), {
      loading: false,
      isFirstLoad: false,
      preSelectedFacets: {}
    });
}

function loadError(state, action) {
  return R.merge(state, { loading: false, errorMsg: action.payload });
}

function updateSort(state, action) {
  const activeSorter = R.find(R.propEq("isActive", true), action.payload);

  return R.merge(state, {
    sorters: action.payload,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      sortBy: activeSorter.value,
      sortOrder: activeSorter.direction,
      page: 1
    })
  });
}

function updateQueryParams(state, action) {
  return R.merge(state, {
    query: R.merge(state.query, action.payload.params),
    facets: action.payload.facets,
    preSelectedFacets: action.payload.preSelectedFacets
  });
}

function incrementPage(state, action) {
  const pageNum = parseInt(state.query.page, 10) + 1;
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_APPEND,
    query: R.merge(state.query, {
      page: pageNum
    })
  });
}

function changePage(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: action.payload
    })
  });
}

function updateKeywords(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      q: action.payload,
      page: 1
    })
  });
}

function changeFacet(state, action) {
  // We want to provide a new facet object to our
  // state with a single facet changed.

  // Lookup the path for the given facetId
  // and change it using assocPath
  const facetId = action.payload.facetId;
  const facetValue = action.payload.facetValue;
  const newFacets = setSelectedOnFacetValues([facetId], facetValue, state.facets);

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1
    })
  });
}

function clearFacetGroup(state, action) {
  const group = action.payload;
  const selectedFacetIds = selectedFacetsFromGroup(group).map(v => v.id);
  const newFacets = setSelectedOnFacetValues(selectedFacetIds, false, state.facets);

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1
    })
  });
}

function clearAllFacets(state, action) {
  const facets = state.facets;
  const selectedFacetIds = facets.reduce( (all, group) => {
     return all.concat(selectedFacetsFromGroup(group).map(v => v.id));
  }, []);

  const newFacets = setSelectedOnFacetValues(selectedFacetIds, false, facets);

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1
    })
  });
}
