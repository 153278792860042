// Open/Close for mobile sliding of filter bar.
// Involves working with elements outside the
// scope of React components, so logic kept here.

export function openFilters() {
  // Note - this depends on page state outside of the React app
  let filters = filtersWrapper();
  if (filters && !filtersAreOpen(filters)) {
    filters.classList.add("is-open");
  }
}

export function closeFilters() {
  let filters = filtersWrapper();
  if (filters) {
    filters.classList.remove("is-open");
  }
}

export function setFiltersOpenDirection() {
  let direction = "is-right";
  let filters = filtersWrapper();

  if (filters) {
    const sibling = filters.closest(".container__col").nextElementSibling;
    if (sibling && sibling.className.indexOf("container__col") >= 0) {
      direction = "is-left";
    }
  }

  filters.classList.add(direction);
}

// when the facet column is 'lifted' absolutely from the container
// it leaves behind an empty stacked container with a bottom margin
// we want to remove that margin -- but only if facets are the
// only components in the column
export function manageContainerWhitespace() {
  let col = parent = filtersWrapper().closest(".container__col");
  if (parent.children.item(0).classList.contains("container__inner")) {
    parent = parent.children.item(0);
  }

  // cast from HTMLCollection to Array
  let children = [...parent.children];
  const childCount = children.reduce((count, child) => {
    if (!child.classList.contains("search__facets-wrapper")) {
      count = count + 1;
    }

    return count;

  } , 0);

  if (childCount === 0) {
    // remove the margin on the parent column
    col.style.marginBottom = 0;
  }
}

function filtersWrapper() {
  return document.querySelector(".search__facets-wrapper");
}

function filtersAreOpen(filtersWrapper) {
  return filtersWrapper.classList.contains("is-open");
}

