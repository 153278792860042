import $ from "jquery";

const   debug = false,
        mobileSearchSelector = ".js-mobile-search";

export default {
    selector: mobileSearchSelector,
    init: init
}

function init() {
    bindEventHandlers();
}

function runMobileSearch() {
    let query = $(mobileSearchSelector).val();
    // send them to search page with query parameter
    // note: it's ok to let them search without a query
    window.location = "/search?q=" + query;
    return false;
}

function bindEventHandlers() {
    $(function(){
        $(".js-mobile-search-submit").on("click", function(event) {
            // run through our custom function instead of standard submit
            runMobileSearch();
            return false;
        });

        $(mobileSearchSelector).on("keypress", function(event) {
            if (event.keyCode === 13) {
                // run through our custom function instead of standard submit
                runMobileSearch();
                return false;
            }
        });
    });
}