import React from "react";
import FacetGroup from "./FacetGroup.jsx";
import FacetTag from "./FacetTag.jsx";
import {closeFilters, setFiltersOpenDirection, manageContainerWhitespace} from "../filterSlider";

export default class FacetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFacets: [],
      pageLoadFacetsSet: false // on page load populate selected facets
    };
  }


  componentWillReceiveProps(nextProps) {
    // only set initial selected facets on page load
    if(nextProps.facets.length > 0 && !this.state.pageLoadFacetsSet) {
      var selectedFacets = this.props.getSelectedFacets(nextProps.facets);
      this.setState({ selectedFacets: [].concat(this.state.selectedFacets, selectedFacets) })
      this.setState({ pageLoadFacetsSet: true });
    }
  }

  clearAll() {
    this.setState({selectedFacets: []});
    this.props.handleClearAll();
  }

  hideFilters() {
    closeFilters();
  }

  // maintain a list of selected facets in order to preserve the order
  // of selection for facet tags
  updateSelectedFacets(facet, toggle) {
    var newFacetList = this.state.selectedFacets;
    if (toggle) {
      this.setState({ selectedFacets: [].concat(newFacetList, facet) });
    } else {
      // remove the facet from the selectedFacets
      var filteredFacetList = newFacetList.filter(e => e.id !== facet.id);
      this.setState({ selectedFacets: filteredFacetList })
    }
  }

  renderFacetGroups() {
    return this.props.facets.map( (group, i) => {
      const numOpenGroups = this.props.numOpenGroups;
      const isOpen = typeof numOpenGroups !== "undefined"
        && numOpenGroups !== null
        && i < numOpenGroups;
      const { dictionary } = this.props;
      return (<FacetGroup key={group.id}
                          handleFacetChange={this.props.handleFacetChange}
                          updateSelectedFacets = {this.updateSelectedFacets.bind(this)}
                          handleClearGroup={this.props.handleClearGroup}
                          isOpen={isOpen}
                          group={group}
                          dictionary={dictionary}/>);
    });
  }

  renderFacetTags() {
    var allSelectedFacets = this.state.selectedFacets;
    return allSelectedFacets.map( facet => {
      return <FacetTag key={facet.id} facet={facet} updateSelectedFacets={this.updateSelectedFacets.bind(this)} handleFacetChange={this.props.handleFacetChange}/>;
    });
  }

  renderClearAll() {
    const { dictionary } = this.props;
    return (
      <button className="btn btn--primary" onClick={() => this.clearAll()}>
          {dictionary.facetsClearAllLabel}
      </button>
    );
  }

  render() {
    const facetGroups = this.renderFacetGroups();
    const facetTags = this.renderFacetTags();
    const hasSelectedFacets = this.state.selectedFacets.length > 0 ? true : false;
    
    return (
      <div>
        <div className="search-full-width">
          <div className="search-facets">
            {facetGroups}
          </div>
        </div>
        {hasSelectedFacets && (
          <section className="search-tags__container">
            <div className="search-tags">
              {facetTags}
            </div>
            {this.renderClearAll()}
          </section>
        )}
      </div>
    );
  }
}

const { string, arrayOf, shape, number, oneOfType, func } = React.PropTypes;
FacetList.propTypes = {
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  handleClearAll: func.isRequired,
  dictionary: shape({
    facetsClearAllLabel: string,
    facetGroupPlaceholder: string,
    facetGroupNoResults: string
  })
};

FacetList.defaultProps = {
  facets: [],
  numOpenGroups: null
};

