import React from "react";
import { isLastPage, isFirstPage, totalPages, pageBlock } from "../paging";

const Pagination = ({
  totalResults,
  resultsPerPage,
  currentPage,
  handlePageChange
}) => {

  const block = pageBlock(totalResults, resultsPerPage, currentPage);
  const isLast = isLastPage(totalResults, resultsPerPage, currentPage);
  const isFirst = isFirstPage(currentPage);
  const lastPage = totalPages(totalResults, resultsPerPage);

  function getPage(label, value, isDisabled) {
    return (
      <button
        className="btn btn--link"
        key={label}
        type="button"
        onClick={() => onPageClick(value, isDisabled)}
        disabled={isDisabled}>{label}</button>
    );
  }

  function onPageClick(pageNum, isDisabled) {
    if (!isDisabled) {
      handlePageChange(pageNum);
    }
  }

  return (
    <div className="search-results__pagination">
      <div className="search-results__pages">
        {getPage("<< First", 1, isFirst)}
        {getPage("< Previous", currentPage - 1, isFirst)}
        {block.map( page => getPage(page.label, page.num, page.current))}
        {getPage("Next >", currentPage + 1, isLast)}
        {getPage("Last >>", lastPage, isLast)}
      </div>
    </div>
  );
};

const { number, func } = React.PropTypes;

Pagination.propTypes = {
  totalResults: number.isRequired,
  resultsPerPage: number.isRequired,
  currentPage: number.isRequired,
  handlePageChange: func.isRequired
};

export default Pagination;
