import $ from "jquery";
import breakpoints from "./helpers/breakpoints.js";
import ee from "./helpers/experience-editor.js";


const   debug = false,
        personFocusSelector = ".js-person-focus-slider";

export default {
    init: init,
    selector: personFocusSelector
};

function init(){
    if(debug){ console.debug("loading person focus"); }

    // handle multiple person focus sliders on a page
    $(personFocusSelector).each(function(index, el){
        if (!ee.editing){
            $(el).slick({
                speed: 700,
                easing: "swing",
                slidesToShow: 1,
                accessibility: true,
                arrows: true,
                centerMode: false
            });
        }
    });
}