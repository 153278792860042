import $ from "jquery";
require("../polyfill/find.js");

const debug = false,
    checkboxClass = ".cookie-warning__checkbox",
    $tabCheckbox = $(checkboxClass),
    checkboxClassOptional = ".cookie-warning__checkbox.optional",
    $tabCheckboxOptional = $(checkboxClassOptional),
    cookieSelector = "#js-cookie-warning",
    $cookiePolicyId = $("#cookiePolicyId"),
    $displayFullBanner = $("#displayFullBanner"),
    $siteLanguageTransition = $("#siteLanguageTransition"),
    $cookieBanner = $("#js-cookie-warning"),
    $toggleButton = $(".cookie-policy-toggle"),
    $cookieSelection = $(".js-cookie__display-tabs"),
    submitAllButtonClass = ".js-cookie-warning__button--submit-all";

export default {init: cookieWarning}

function cookieWarning() {
    bindEvents();
    //setCookieCheckboxes();
    var cookieValue = getCookie("cookieSeen");
    if (cookieValue.indexOf($cookiePolicyId.val()) > -1 && $siteLanguageTransition.val() === "0") {
        return;
    } else {
        $cookieBanner.remove();
        $cookieBanner.prependTo("body");

        //show cookie
        $cookieBanner.removeClass("is-hidden");

        //setCookieSubmit();
        setFocusTrap(submitAllButtonClass);
    }
}

function bindEvents() {
    $toggleButton.on("click", toggleCookieBanner);
}

function toggleCookieBanner(e) {
    e.preventDefault();
    $cookieBanner.removeClass("is-hidden");
    $cookieSelection.removeClass("is-hidden");
    setFocusTrap(submitAllButtonClass);
}

function setCookieSubmit() {
    // var cookieValue = getCookie("cookieSeen");
    setCookie("cookieSeen", getCheckboxValue() + "|currlang:" + $("html").attr("lang"));
}

function checkAll() {
    $tabCheckbox.prop("checked", true);

    $tabCheckbox.each(function() {
      let id = $(this).attr("id");

      if (id == "4058977") {
        $(this).prop('checked', false);
      }
    });
}

function clearAll() {
    $tabCheckboxOptional.prop("checked", false);
}

function getCheckboxValue() {
    let cookieContent = [];
    $tabCheckbox.each(function() {
        let cookieStatus = $(this).is(":checked")
            ? "1"
            : "0";
        cookieContent.push($(this).attr('id') + ":" + cookieStatus);

    });
    return cookieContent.join("|");
}

// If cookies have already been set, check the corresponding checkbox
function setCookieCheckboxes() {
  let cookieValues = getCookie("cookieSeen");
  let valuesArray = cookieValues.split("|");

  // create searchable object
  let munchedValues = valuesArray.map(function(val) {
    let singleCookie = val.split(":");
    return {
      name: singleCookie[0],
      status: singleCookie[1]
    }
  });

  $tabCheckbox.each(function() {
    let id = $(this).attr("id");

    // match cookies with checkboxes and check if the cookie is set
    let cookieIsSet = munchedValues.find(function(item) {
      return (item.name === id) && (item.status == "1");
    });

    if (cookieIsSet) {
      $(this).prop('checked', true);
    }
  });
}


/* getCookie straight from w3schools; gets a specific cookie value */
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}
function setCookie(cname, cvalue) {
    let d = new Date(new Date().setMonth(new Date().getMonth() + 6));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function setFocusTrap(lastElement) {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(cookieSelector); // select the modal by it's id

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = modal.querySelector(lastElement);//focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    document.addEventListener('keydown', function (e) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus(); // add focus for the last focusable element
                e.preventDefault();
            }
        } else { // if tab key is pressed
            if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstFocusableElement.focus(); // add focus for the first focusable element
                e.preventDefault();
            }
        }
    });

    firstFocusableElement.focus();
}
