import $ from "jquery";
window.$ = $;
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import ee from "./helpers/experience-editor.js";

const   debug = false,
        photoGallerySelector = ".js-photo-gallery-slider";

export default {
    init: initPhotoGallerySlider,
    selector: photoGallerySelector
}

function initPhotoGallerySlider() {
    $(function(){
        if (!ee.editing){
            $(photoGallerySelector).slick({
                speed: 700,
                easing: "swing",
                slidesToShow: 1,
                accessibility: true,
                arrows: true,
                centerMode: false,
                infinite: false,
                dots: true
            });
        }
    });
}
