import $ from "jquery";
import cookie from "./helpers/cookie.js";
import breakpoints from "./helpers/breakpoints.js";

const debug = false,
    contrastToggleSelector = ".js-contrast-toggle",
    $contrastToggle = $(contrastToggleSelector),
    regularStylesheet = "index-generated.css",
    highContrastStylesheet = "high-contrast-generated.css",
    stylesheetPath = "/assets/build/css/";

var inHighContrastMode,
    breakpoint = breakpoints.find();

export default {init: init, selector: contrastToggleSelector}

function init() {

    let acceptCookies = cookie.get("cookieSeen");
    if (acceptCookies.indexOf("inHighContrastMode:1") > -1) {

        // if cookie is set, switch to contrast mode
        inHighContrastMode = cookie.get("inHighContrastMode");
        if (debug) {
            console.debug("inHighContrastMode: " + inHighContrastMode);
        }
        // cookie returns value as a string, we're converting it to a boolean
        inHighContrastMode === "true"
            ? inHighContrastMode = true
            : inHighContrastMode = false;

        // visually show or hide label
        if (breakpoint === "small") {
            $(".contrast-toggle__label").removeClass("screen-reader-only");
        }

        setButtonClass();
        setButtonScreenReaderText();
        bindHandler();
    }
}

function switchStylesheet() {
    inHighContrastMode
        ? $("#theme-stylesheet").attr("href", stylesheetPath + highContrastStylesheet)
        : $("#theme-stylesheet").attr("href", stylesheetPath + regularStylesheet);
}

function toggleContrastMode() {
    inHighContrastMode = inHighContrastMode
        ? false
        : true;
    setButtonClass();
    setButtonScreenReaderText();
    switchStylesheet();
    cookie.set("inHighContrastMode", inHighContrastMode);
}

function setButtonClass() {
    // add or remove toggle button state class
    inHighContrastMode
        ? $contrastToggle.addClass("contrast-toggle--on")
        : $contrastToggle.removeClass("contrast-toggle--on");
}

function setButtonScreenReaderText() {
    // add or remove toggle button state class
    if (inHighContrastMode) {
        $(".js-contrast-off").show();
        $(".js-contrast-on").hide();
    } else {
        $(".js-contrast-on").show();
        $(".js-contrast-off").hide();
    }
}

function bindHandler() {
    $(function() {
        $contrastToggle.on("click", function() {
            toggleContrastMode();
        });
    });
}

$(window).trigger("resize", function() {

  breakpoint = breakpoints.find();

  if (breakpoint === "small") {
      $(".contrast-toggle__label").removeClass("screen-reader-only");
  }
  else
  {
      $(".contrast-toggle__label").addClass("screen-reader-only");
  }
});
