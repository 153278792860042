import $ from "jquery";

const   debug = false,
        officesSearchSelector = ".js-offices-search",
        $countriesSelect = $("#countries"),
        $regionsSelect = $("#regions"),
        $officesList = $("#offices"),
        $officesSection = $(".js-office-results-section"),
        allOffices = $officesList.children(".js-single-office"),
        $regionsSection = $(".js-search-regions-section"),
        $statement = $(".js-local-statement"),
        $nearestOfficeDiv = $(".js-nearest-office");

var     countries,
        regions,
        offices,
        selectedCountryId,
        selectedRegionId;

export default {
    init: init,
    selector: officesSearchSelector
};

function init(officesSearchSelector) {
    if(debug){ console.debug("loading offices search"); }

    bindHandlers();

    // note: officeDataJson printed directly to page
    countries = officeDataJson.Countries;
    regions = officeDataJson.States;
    offices = officeDataJson.Offices;

    populateSelect($countriesSelect, countries);

    if (hasCurrentLocation) {
        // note: hasCurrentLocation, curLat, & curLon
        // come from script printed in offices.cshtml
        getNearestOffice(curLat, curLon);
    }
}

function bindHandlers() {
    $(function(){
        // handle country select
        $countriesSelect.change(function(){
            countryChange();
        });

        // handle state select
        $regionsSelect.change(function(){
            regionChange();
        });

    });
}

function populateSelect($field, data) {
    $field.not(':first').remove();

    $.each(data, function(i, item){
        $field.append($('<option>').text(item["Name"]).attr('value', item["Id"]));
    });
}

function getSelectedLocation(array, id) {
    return array.find( function(item) {
        return item.Id == id;
    });
}

/* Countries Functions:
    - countryChange
*/
function countryChange() {
    if(debug) { console.debug("country changed"); }
    var value = $countriesSelect.val();
    selectedCountryId = value;

    clearAndHideRegions();
    clearAndHideOffices();
    getCountryStatement();

    value ? getRegions() : console.log("error filtering regions");
}

/* Regions Functions:
    - regionChange
    - getRegions
    - clearAndHideRegions
*/
function regionChange() {
    var value = $regionsSelect.val();

    if (value) {
        selectedRegionId = value;
        getOffices();
    } else {
        selectedRegionId = null;
        clearAndHideOffices();
    }

    getRegionStatement();
}

function getRegions() {
    if(debug) {console.debug("getRegions()"); }

    let filteredRegions = regions.filter(function(region) {
        return region.CountryId == selectedCountryId;
    });

    if (filteredRegions.length > 0) {
        // show region selector
        populateSelect($regionsSelect, filteredRegions);
        $regionsSection.show();
        clearAndHideOffices();
    } else {
        // show offices for selected country
        if(debug){ console.debug("country has no regions")}
        getOffices();
    }
}

function clearAndHideRegions() {
    selectedRegionId = null;
    $regionsSection.hide();
}

/* Offices Functions:
    - getOffices
    - clearAndHideOffices
    - getNearestOffice
    - showNearestOffice
*/
function getOffices() {
    if(debug) {console.debug("getOffices()"); }

    $(allOffices).each( function(i, office) {
        let $office = $(office);
        if ($office.attr("data-location-id") == selectedCountryId || $office.attr("data-location-id") == selectedRegionId) {
            $office.addClass("is-a-result");
        } else {
            $office.removeClass("is-a-result");
        }
    });

    if ($officesList.find(".is-a-result").length > 0) {
        $officesSection.show();
    } else {
        if(debug) {console.debug("no offices for selected country or region"); }
        clearAndHideOffices();
    }
}

function clearAndHideOffices() {
    $(allOffices).each(function(i, office) {
        $(office).removeClass("is-a-result");
    })
    $officesSection.hide();
}

function getNearestOffice(yourLat, yourLon) {
    if(debug) { console.debug("getting nearest office to " + yourLat + ", " + yourLon)}

    function getDistanceFromYou(office) {
        let lat1 = yourLat;
        let lon1 = yourLon;
        let lat2 = office.Latitude;
        let lon2 = office.Longitude;

        const earthRadiusKm = 6371;

        // Formula From: http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula?page=1&tab=votes#tab-top

        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        var dLat = deg2rad(lat2-lat1);
        var dLon = deg2rad(lon2-lon1);
        var a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = earthRadiusKm * c;
        return d; // Distance in km
    }

    // Determine nearest office
    if(debug) { console.time("Time to determine nearest office"); }
    const closestOffice = offices
        .reduce((closest, office) => {
            const thisDistance = getDistanceFromYou(office);

            if (thisDistance < closest.distance) {
                return {
                    distance: thisDistance,
                    office: office
                }
            }
            else {
                return closest;
            }
        }, {distance: Infinity, office: null});
    if(debug) {
        console.debug(closestOffice.office.City + " is the nearest office, " + closestOffice.distance + "km away");
        console.timeEnd("Time to determine nearest office");
    }
    if (closestOffice) {
        showNearestOffice(closestOffice.office);
    }
}

function showNearestOffice(officeItem) {
    // using find to search all office divs. faster than each
    function checkOfficeId(office) {
        return $(office).attr("data-office-id") == officeItem.Id;
    }
    let allOfficesArray = $(allOffices).toArray(); // so we can use array function
    let nearestOffice = allOfficesArray.find(checkOfficeId);

    // ?? not sure we need to clone if we're not altering it
    let $nearestOfficeClone = $(nearestOffice).clone();
    // insert the result into the view
    $nearestOfficeClone.prependTo($nearestOfficeDiv);
}


/* Local Statement Functions:
    - getCountryStatement
    - getRegionStatement
    - setStatement
    - clearAndHideStatement
*/
function getCountryStatement() {
    if (selectedCountryId) {
        let country = getSelectedLocation(countries, selectedCountryId);
        if (country.LocalStatement) {
            setStatement(country.LocalStatement);
        } else {
            clearAndHideStatement();
        }
    } else {
        clearAndHideStatement();
    }
}

function getRegionStatement() {
    if (selectedRegionId) {
        let region = getSelectedLocation(regions, selectedRegionId);
        if (region.LocalStatement) {
            setStatement(region.LocalStatement);
        } else {
            getCountryStatement();
        }
    } else {
        getCountryStatement();
    }
}

function setStatement(statement) {
    $statement.html(statement).show();
}

function clearAndHideStatement() {
    $statement.hide().html("");
}
