//imports.
import $ from "jquery";

// Initialize the form when the DOM content is loaded
document.addEventListener("DOMContentLoaded", function () {
    init();
});

// Initialize the form by binding handlers and exposing functions to the global scope
function init() {
    bindHandlers();
    // Ensure these functions are included in the bundle
    window.showPopupContactForm = showPopupContactForm;
    window.closeEmailForm = closeEmailForm;
}

// Bind event handlers to the form elements
function bindHandlers() {
    document.getElementById('expertContactForm').addEventListener('submit', function (e) {
        e.preventDefault();

        // Display loading spinner and disable submit button
        const submitBtn = document.getElementById('expertSubmitBtn');
        submitBtn.disabled = true;
        document.getElementById('loadingSpinner').style.display = 'inline';
        document.getElementById('submitBtnText').style.display = 'none';

        // Clear previous error messages
        clearErrorMessages();

        let isValid = true;
        const formData = new FormData(this);
        const requiredFieldMessage = document.getElementById('requiredFieldMessage').value;
        const invalidEntryMessage = document.getElementById('invalidEntryMessage').value;
        const recaptchaErrorMessage = document.getElementById('recaptchaErrorMessage').value;
        const isCompanyOptional = document.getElementById('isCompanyOptional').value === 'true';
        const isJobTitleOptional = document.getElementById('isJobTitleOptional').value === 'true';

        // Retrieve label values from hidden input tags
        const labels = {
            FirstName: document.getElementById('firstNameLabel').value,
            LastName: document.getElementById('lastNameLabel').value,
            Company: document.getElementById('companyLabel').value,
            JobTitle: document.getElementById('jobTitleLabel').value,
            BusinessEmail: document.getElementById('businessEmailLabel').value,
            Message: document.getElementById('messageLabel').value
        };

        // Validate required fields
        formData.forEach((value, key) => {
            if (!value) {
                const errorEl = document.getElementById('expert' + key + 'Error');
                if (errorEl) {
                    if ((key === 'Company' && !isCompanyOptional) || (key === 'JobTitle' && !isJobTitleOptional) || (key !== 'Company' && key !== 'JobTitle')) {
                        errorEl.textContent = labels[key] + ' ' + requiredFieldMessage;
                        errorEl.setAttribute('aria-invalid', 'true');
                        isValid = false;
                    }
                }
            }
        });

        // Validate email format.
        const email = formData.get('BusinessEmail');
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            const emailErrorEl = document.getElementById('expertBusinessEmailError');
            emailErrorEl.textContent = invalidEntryMessage;
            emailErrorEl.setAttribute('aria-invalid', 'true');
            isValid = false;
        }

        // Validate Recaptcha
        const recaptchaResponse = grecaptcha.getResponse();
        if (!recaptchaResponse) {
            const recaptchaErrorEl = document.getElementById('expertRecaptchaError');
            recaptchaErrorEl.textContent = recaptchaErrorMessage;
            recaptchaErrorEl.setAttribute('aria-invalid', 'true');
            isValid = false;
        } else {
            formData.append('recaptcharesponse', recaptchaResponse);
        }

        // Capture the current page URL
        formData.append('CurrentPageUrl', window.location.href);

        // Send the form data via AJAX if validation passes
        if (isValid) {
            // Serialize the form data
            const formDataString = new URLSearchParams(formData).toString();

            // Close the form immediately after posting the data
            closeEmailForm();

            $.ajax({
                type: 'POST',
                url: '/api/expertcontact/SendEmail',
                data: formDataString,
                contentType: 'application/x-www-form-urlencoded'
            })
                .done(function (data, textStatus, jqXHR) {
                    if (jqXHR.status !== 200) {
                        console.error(`Email submission failed with status: ${jqXHR.status}`);
                    }
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    console.error(`Email submission error: ${textStatus}, ${errorThrown}`);
                });
        } else {
            // Re-enable submit button and hide loading spinner if validation fails
            submitBtn.disabled = false;
            document.getElementById('loadingSpinner').style.display = 'none';
            document.getElementById('submitBtnText').style.display = 'inline';
        }
    });
}

// Show the expert email form when the button is clicked
function showPopupContactForm() {
    const formContainer = document.getElementById('expertContactFormContainer');
    formContainer.style.display = 'block';
    renderRecaptcha(); // Render or reset reCAPTCHA when the form becomes visible
    positionModal('#expertContactFormPopup', '#expertContactFormOverlay');
    updateOptionalFieldStyle();
}

// Check if Company and JobTitle are optional and add a class to their labels
function updateOptionalFieldStyle() {
    const isCompanyOptional = document.getElementById('isCompanyOptional').value === 'true';
    const isJobTitleOptional = document.getElementById('isJobTitleOptional').value === 'true';

    if (isCompanyOptional) {
        document.querySelector('label[for="expertCompany"]').classList.add('optional-field');
    }
    if (isJobTitleOptional) {
        document.querySelector('label[for="expertJobTitle"]').classList.add('optional-field');
    }
}

// Close the expert email form when the close button is clicked
function closeEmailForm() {
    resetAndCloseForm();
    document.getElementById('expertContactFormContainer').style.display = 'none';
}

// Reset the form and close it
function resetAndCloseForm() {
    document.getElementById('expertContactForm').reset();
    clearErrorMessages();
    grecaptcha.reset();

    // Re-enable submit button and hide loading spinner
    const submitBtn = document.getElementById('expertSubmitBtn');
    submitBtn.disabled = false;
    document.getElementById('loadingSpinner').style.display = 'none';
    document.getElementById('submitBtnText').style.display = 'inline';
}

// Clear all error messages from the form
function clearErrorMessages() {
    document.querySelectorAll('.expert-error-message').forEach(function (el) {
        el.textContent = '';
        el.removeAttribute('aria-invalid');
    });
}

// Render or reset the reCAPTCHA widget
function renderRecaptcha() {
    const recaptchaContainer = document.getElementById('recaptchaContainer');
    const siteKey = recaptchaContainer.getAttribute('data-sitekey');

    if (typeof grecaptcha !== 'undefined' && recaptchaContainer.hasChildNodes()) {
        grecaptcha.reset();
    } else {
        grecaptcha.render('recaptchaContainer', {
            'sitekey': siteKey
        });
    }
}

// Position the modal in the center of the screen
function positionModal(modalSelector, maskSelector) {
    const modal = document.querySelector(modalSelector);
    const mask = document.querySelector(maskSelector);

    if (!modal || !mask) {
        console.error("Modal or mask element not found.");
        return;
    }

    // Update the position of the modal and mask
    function updatePosition() {
        const modalRect = modal.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Center the modal horizontally and position it at the top of the visible window area
        let left = Math.max(0, (windowWidth - modalRect.width) / 2);
        const top = Math.max(10, (windowHeight - modalRect.height) / 2);

        // Adjust left position for mobile screens
        if (windowWidth < 768) { // Assuming 768px as the breakpoint for mobile screens
            left = Math.max(10, (windowWidth - modalRect.width) / 4);
        }

        modal.style.left = `${left}px`;
        modal.style.top = `${window.scrollY + top}px`;

        // Update mask height
        mask.style.height = `${Math.max(document.body.scrollHeight, windowHeight)}px`;
    }

    // Initial positioning
    updatePosition();

    // Reposition modal on window resize
    window.addEventListener("resize", updatePosition);
}