import React from "react";

const SearchStatus = ({
  resultsRange,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
  dictionary
}) => {

  const shouldRenderKeywords = !!keywords;

  const resultLabel = totalResults > 1 || totalResults === 0 ? dictionary.metadataResults : dictionary.metadataResult;

  return (
    <p className="search-results__status">
      <span>{dictionary.metadataShowing}</span>&nbsp;
      <strong>{resultsRange}</strong>&nbsp;
      <span>{dictionary.metadataOf} {totalResults} {resultLabel}. {dictionary.metadataHelpText}</span>
    </p>
  );

};

const { string, number, object, arrayOf, func, shape } = React.PropTypes;

SearchStatus.propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  selectedFacets: arrayOf(object).isRequired,
  handleFacetDismiss: func.isRequired,
  dictionary: shape({
    metadataResults: string,
    metadataResult: string,
    metadataShowing: string,
    metadataOf: string,
    metadataHelpText: string
  })
};

SearchStatus.defaultProps = {
  selectedFacets: []
};

export default SearchStatus;
