/* import libraries & plugins */
import $ from 'jquery';

/* import helper code */
import debounce from "./helpers/debounce.js";
import breakpoints from "./helpers/breakpoints.js";
import waypoints from "./helpers/jquery.waypoints.js";
import hoverIntent from "./helpers/hoverintent.js";/* are we still using this? */
import ee from "./helpers/experience-editor.js";
import cookie from "./helpers/cookie.js";
import "./helpers/number-tolocalestring-polyfill.js";
import objectFitVideos from "./helpers/ofv.js"; // polyfill has been modified

/* import global component code */
import toggleNav from "./toggle-nav.js";
import regionSelector from "./region-toggle.js";
import backToTop from "./back-to-top.js";
import cookieWarning from "./cookie-warning.js";
import toggleContrast from "./toggle-contrast.js";

/* import search code */
import searchTakeover from "./search-takeover.js";
import searchMobile from "./search-mobile.js";
import {initSearchApp} from "./search/index.js";

/* import component code */
import teaserSlider from "./teaser-slider.js";
import expertSlider from "./expert-slider.js";
import photoGallerySlider from "./photo-gallery-slider.js";
import largeCarouselSlider from "./large-carousel-slider.js";
import personFocusSlider from "./person-focus-slider.js";
import accordionGallery from "./accordion-gallery.js";
import featuredPeople from "./featured-people.js";
import vidjsPlaylist from "./videojs.playlist.js";
//import bannerVid from "./banner-video.js";
//import componentVid from "./video-component.js";
import statistics from "./statistics.js";
import socialShare from "./social-share.js";
import peopleRiver from "./people-river.js";
import emailForm from "./email-form.js";
import accommodationRequestForm from "./accommodation-request-form.js";
import individualRightsRequestForm from "./individual-rights-request-form.js";
import officesSearch from "./find-an-office.js";
import officesMap from "./offices-map.js";
import maskingInput from "./masking-input.js";
import expertContactForm from "./expert-contact-form.js";
const debug = false;

// Cookie stuff!
import cookieWarningTabs from "./cookie-warning-tabs.js";
import cookieWarningSelect from "./cookie-warning-submit";
/** HERE'S THE WAY IT'S GONNA BE, BUDDY:
    - Every function call here should be wrapped in runIfExists().
    - Components should not do their own check for existence,
        let index.js do that.
    - (In most cases) every component-specific .js file should have at least:
        1) an exported selector variable
        2) an exported init()
    - If a component needs dynamic bindings, put the click handlers in the
        component's js file wrapped inside an exported function which
        contains a document.ready. Call that function here to bind only if the
        component exists
    - If a component needs breakpoint info, it should import
        breakpoints.js, not calculate it itself
**/

function runIfExists(componentSelector, functionToRun) {
    if ($(componentSelector).length > 0) {
        functionToRun();
    }
}

$(function() {
    // report current breakpoint
    breakpoints.init();
    if (debug) {
        console.debug("current breakpoint is " + breakpoints.current());
    }

    // report whether or not we're in  experience editor
    ee.init();

    // add accessibility features to addToAny
    socialShare.init();

    // object-fit polyfill
    window.objectFitImages();
    objectFitVideos();


    // contrast mode
    runIfExists(toggleContrast.selector, () => toggleContrast.init());

    // email form
    runIfExists(emailForm.selector, () => emailForm.init());

    // accommodation request form
    runIfExists(accommodationRequestForm.selector, () => accommodationRequestForm.init());

    // individual rights request form
    runIfExists(individualRightsRequestForm.selector, () => individualRightsRequestForm.init());

    // search takeover
    runIfExists(searchTakeover.selector, () => searchTakeover.init());

    // mobile search
    runIfExists(searchMobile.selector, () => searchMobile.init());

    // offices search
    runIfExists(officesSearch.selector, () => officesSearch.init());

    // offices map
    runIfExists(officesMap.selector, () => officesMap.init());

    // people river
    runIfExists(peopleRiver.selector, () => peopleRiver.init());
    // $(window).on('resize', peopleRiver.init());

    // statistics
    runIfExists(statistics.selector, () => statistics.init());

    // accordion gallery
    runIfExists(accordionGallery.selector, () => accordionGallery.init());

    // featured people
    runIfExists(featuredPeople.selector, () => featuredPeople.init());

    // expert contact form
    runIfExists(expertContactForm.selector, () => expertContactForm.init());

    // quote & hero banner videos
    // runIfExists(bannerVid.selector,
    //             () => bannerVid.init());

    // video component
    // runIfExists(componentVid.selector,
    //             () => componentVid.init());

    // enable form field masking
    runIfExists(maskingInput.selector, () => maskingInput.init());

    // person focus component
    runIfExists(personFocusSlider.selector, () => personFocusSlider.init());

    // related experts component
    runIfExists(expertSlider.selector, () => expertSlider.init());

    // teaser blocks component
    runIfExists(teaserSlider.selector, () => teaserSlider.init());

    // large carousel component
    runIfExists(largeCarouselSlider.selector, () => largeCarouselSlider.init());

    // photo gallery component
    runIfExists(photoGallerySlider.selector, () => photoGallerySlider.init());

    /* Search */
    if ($(".js-search-results").length) {
        initSearchApp($.makeArray($(".js-search-query,.js-search-results,.js-search-facets")));
    }

    /*
     * Show/hide cookie warning legalese
     * Always runs, no checks
     */
    cookieWarning.init();
    // cookieWarningSelect.init();
    runIfExists(cookieWarningSelect.selector, () => cookieWarningSelect.init());
    runIfExists(cookieWarningTabs.selector, () => cookieWarningTabs.init());

});

$(window).on("resize", debounce(function() {
    // recalculate current breakpoint
    breakpoints.init();
    if (debug) {
        console.debug("resize detected - index " + breakpoints.current());
    }
    // related experts component
    runIfExists(expertSlider.selector, () => expertSlider.init());

    // teaser blocks component
    runIfExists(teaserSlider.selector, () => teaserSlider.init());

    // accordion gallery: recalculate container sizes
    runIfExists(accordionGallery.selector, () => accordionGallery.calculateContentSizes(accordionGallery.selector));

    // featured people:
    // check if we need to slick/unslick
    // reset waypoints??

    runIfExists(featuredPeople.selector, () => featuredPeople.reset());

    runIfExists(peopleRiver.selector, () => peopleRiver.reset());

    // expert contact form
    runIfExists(expertContactForm.selector, () => expertContactForm.init());
}, 250));
