import {connect} from "react-redux";
import SearchBox from "../components/SearchBox.jsx";
import { loadNewKeywords } from "../redux/search/search.actions";

const mapStateToProps = (state) => {
  return {
    keywords: state.query.q,
    dictionary: state.dictionary,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEntry: (value) => {
      dispatch(loadNewKeywords(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
