import $ from "jquery";
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import breakpoints from "./helpers/breakpoints.js";


const debug = false,
    gallerySelector = ".js-accordion-gallery",
    slideSelector = ".js-accordion-slide";

export default {
    init: init,
    selector: gallerySelector,
    calculateContentSizes: calculateContentSizes,
    bindClickHandlers: bindClickHandlers
};



function init() {
    if (debug) { console.debug("loading accordion gallery"); }

    // handle multiple accordion galleries on a page
    $(gallerySelector).each(function (index, el) {

        calculateContentSizes(el);

        /* add js class to open panels on click */
        $(el).find(slideSelector).each(function (index, slide) {
            $(slide).addClass("js-open-accordion-panel");
        });

        bindClickHandlers();
    });
}

function calculateContentSizes(accordionGallery) {
    /** NOTE: IF SIZE OF EXPANDED SLIDE IS UPDATED IN SCSS
        IT ALSO NEEDS TO BE UPDATED HERE **/
    let expandedFlexSize = 6,
        currentView = breakpoints.current(),
        galleryWidth,
        galleryHeight,
        numSlides,
        numColumns,
        colWidth,
        colHeight,
        expandedWidth,
        expandedHeight,
        $accordionGallery = $(accordionGallery);

    // get window dimensions
    galleryWidth = $accordionGallery.width();
    galleryHeight = $accordionGallery.height();

    // get # of slides
    numSlides = $accordionGallery.find(slideSelector).length;

    // total # of columns is 1 each, except expanded which gets more columns
    numColumns = expandedFlexSize + (numSlides - 1);

    // calulate size of image needed -- this depends on breakpoint
    if (currentView === "large") {
        colWidth = galleryWidth / numColumns;
        expandedWidth = colWidth * expandedFlexSize;
        expandedHeight = galleryHeight;
    } else if (currentView === "small" || currentView == "medium") {
        colHeight = galleryHeight / numColumns;
        expandedWidth = galleryWidth;
        expandedHeight = colHeight * expandedFlexSize;
    }

    sizeImageContainer($accordionGallery, expandedWidth, expandedHeight);
    sizePreviewContainer($accordionGallery, galleryWidth, numSlides, currentView);
}

function sizeImageContainer($accordionGallery, imgWidth, imgHeight) {
    // sets image container to size it should be when expanded
    $accordionGallery.find(".js-accordion-slide-container").each(function (index, el) {
        $(el).css({
            "width": imgWidth + 1, // +1 px to fix subpixel rendering / rounding differences btwn browsers
            "height": imgHeight + 1
        });
    });
}

function sizePreviewContainer($accordionGallery, galleryWidth, numSlides, currentView) {
    // calculate width of preview container
    const width = (currentView === "large") ? galleryWidth / numSlides : "auto";

    // set preview container to size it should be when expanded
    $accordionGallery.find(".js-accordion-preview-container").each(function (index, el) {
        $(el).css("width", width);
    });
}

function showPreviews(accordionGallery) {
    if (debug) { console.debug("showing previews"); }
    // add preview classes
    $(accordionGallery).find(slideSelector).each(function (index, el) {
        $(el).addClass("accordion-slide--preview js-open-accordion-panel");
    });
}

function resetSlide(accordionSlide) {
    // remove all display state classes (except the ones we want) from single slide
    $(accordionSlide).attr("class", "accordion-slide js-accordion-slide");
}

function resetAccordionGallery(accordionGallery) {
    // remove all display state classes from all slides in gallery
    $(accordionGallery).find(slideSelector).each(function (index, el) {
        if (debug) { console.debug("resetting slide " + index); }
        resetSlide($(el));
    });
}

function checkForVideo($accordionSlide) {
    return $accordionSlide.find(".video-js").length > 0 ? true : false;
}

function collapseSlide(accordionSlide) {
    let $accordionSlide = $(accordionSlide);
    if (checkForVideo($accordionSlide)) {
        stopVideo($accordionSlide);
        $accordionSlide.find(".js-play-accordion-video").attr("tabindex", "-1");
    }
    resetSlide(accordionSlide);
    $accordionSlide.addClass("accordion-slide--collapsed");
}

function expandSlide(accordionSlide) {
    let $accordionSlide = $(accordionSlide);
    if (!$accordionSlide.hasClass('accordion-slide--expanded')) {
        /* Don't do this stuff if it's already open... */
        resetSlide($accordionSlide);
        $accordionSlide.addClass("accordion-slide--expanded js-close-accordion-panel");
        $accordionSlide.siblings().each(function (index, el) {
            collapseSlide(el);
        });

        if (checkForVideo($accordionSlide)) {
            $accordionSlide.find(".js-play-accordion-video").attr("tabindex", "0");
        }
    }
}

function getVideoId($accordionSlide) {
    return $accordionSlide.find(".video-js").attr("id");
}

function playVideo($accordionSlide) {
    if (debug) { console.debug("playing video") }
    // show video
    $accordionSlide.find(".js-accordion-expanded").fadeOut();
    $accordionSlide.find(".js-accordion-video-container").fadeIn();

    // play video
    videojs(getVideoId($accordionSlide)).ready(function () {
        let myPlayer = this;
        let isPlaying = !myPlayer.paused();
        if (!isPlaying) {
            myPlayer.play();
        }
    });
}

function stopVideo($accordionSlide) {
    // hide video, if visible
    if ($accordionSlide.find(".js-accordion-video-container").is(":visible")) {
        $accordionSlide.find(".js-accordion-video-container").fadeOut();
        $accordionSlide.find(".js-accordion-expanded").fadeIn();
    }

    // if video is playing, pause then reset to beginning
    videojs(getVideoId($accordionSlide)).ready(function () {
        let myPlayer = this;
        let isPlaying = !myPlayer.paused();
        if (isPlaying) {
            if (debug) { console.debug("stopping video") }
            myPlayer.currentTime(0);
            myPlayer.pause();
        }
    });
}

function handleCloseButton(accordionSlide) {
    let $accordionSlide = $(accordionSlide);
    let $gallery = $accordionSlide.closest(gallerySelector);
    if (checkForVideo($accordionSlide)) {
        stopVideo($accordionSlide);
        $accordionSlide.find(".js-play-accordion-video").attr("tabindex", "-1");
    }
    resetAccordionGallery($gallery);
    showPreviews($gallery);
}

function bindClickHandlers() {
    $(function () {

        // handle expanding a panel
        $(".js-accordion-slide").on("click", function () {
            expandSlide($(this).closest(slideSelector));
        });

        // handle custom video play button
        $(".js-play-accordion-video").on("click", function () {
            playVideo($(this).closest(slideSelector));
        });

        // handle X button
        $(".js-close-accordion-panel").on("click", function (event) {
            // quit bubbling so we don't trigger a click on the slide
            event.stopPropagation();
            handleCloseButton($(this).closest(slideSelector));
        });

        $(".accordion-slide__headline").on('keypress', function (event) {
            if (event.which === 13 || event.which === 32) {
                expandSlide($(this).closest(slideSelector));
            }
        });
    });
}
