import React from "react";
import R from "ramda";
import classnames from "classnames";
import {
  valuesWithSelectedChildren,
  selectedFacetsFromGroup
} from "../redux/facets";

const MAX_FACETS_SHOWN = 5;

export default class FacetGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroupOpen: true,
      isGroupExpanded: false,
      valuesExpanded: [],
      searchTerm: "",
      facetGroupClicked: false
    };

    this.pageClickHandler = this.pageClickHandler.bind(this);
  }

  // Lifecycle

  componentWillMount() {
    // if group is closed, but has selected
    // values, open it
    const group = this.props.group;

    const selected = selectedFacetsFromGroup(group);
    let shouldOpen = this.state.isGroupOpen;
    if (selected.length > 0) {
      shouldOpen = true;
    }

    // Some facets will be expanded by default due to
    // children being pre-selected
    const hasSelected = valuesWithSelectedChildren(group);
    const selectedIds = hasSelected.map( v => v.id);

    // similarly, if group's values or value children
    // are selected, auto-expand that group
    const tailValues = R.drop(MAX_FACETS_SHOWN, group.values);
    const shouldExpand = tailValues.reduce( (should, tv) => {
      if (tv.selected === true || selectedIds.indexOf(tv.id) >= 0) {
        should = true;
      }

      return should;

    }, false);

    window.addEventListener('mousedown', this.pageClickHandler, false);

    this.setState({
      isGroupOpen: shouldOpen,
      isGroupExpanded: true,
      valuesExpanded: selectedIds
    });
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.pageClickHandler, false);
  }

  pageClickHandler() {
      if (!this.state.facetGroupClicked) {
        this.setState({ showFacetDropdown: false })
      }
  }

  facetRefinementMenuHandler(event) {
    const wasEscapePressed = event.keyCode === 27;

    if (wasEscapePressed) {
      this.pageClickHandler();
      this.restoreArrowKeyScrolling();
    }
  }

  chunkValues(values) {
    // divide the values into two arrays, the first for the Top X facets
    // and the second for overflow. If recursion level is over zero, put
    // all values in first array.
    return values.length > MAX_FACETS_SHOWN
        ? R.splitAt((MAX_FACETS_SHOWN), values)
        : [values, []];
  }

  showExpandButton() {
    return !this.state.isGroupExpanded;
  }

  shouldRenderTailValues(tailValues) {
    return this.state.isGroupExpanded
      && tailValues.length > 0;
  }

  shouldRenderChildGroups(group) {
    return group.isOpen
      && !!group.childGroups
      && group.childGroups.length > 0;
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
  }

  toggleExpand() {
    const isExpanded = !this.state.isGroupExpanded;
    this.setState({ isGroupExpanded: isExpanded });
  }

  toggleValueExpand(valueId) {
    // check if the valueId is in the list. Remove it
    // if found, add it if not.
    const valueIdx = this.state.valuesExpanded.indexOf(valueId);
    const selectedValues = valueIdx >= 0
      ? R.remove(valueIdx, 1, this.state.valuesExpanded)
      : R.append(valueId, this.state.valuesExpanded);

    this.setState({ valuesExpanded: selectedValues });
  }

  valueIsExpanded(value) {
    return this.state.valuesExpanded.indexOf(value.id) >= 0;
  }

  onFacetChange(event, value) {
    this.setState({ activeDescendant: event.target.id });
    this.props.updateSelectedFacets(value, event.target.checked);
    this.props.handleFacetChange(value.id, event.target.checked);
    this.restoreArrowKeyScrolling();
  }

  onFacetKeyUp(event) {
    const visibleFacetsDropdownId = this.getFilteredFacets(this.state.searchTerm).id + '_dropdown';
    const keyPressed = event.keyCode;
    const initialFocusHolder = this.getPositionInParentElement(visibleFacetsDropdownId, event.target);
    const isFocusAtBeginning = initialFocusHolder === 0;
    const isFocusAtEnd = initialFocusHolder === this.getNumberOfSiblings(visibleFacetsDropdownId); 
    let nextFocusIndex = 0;
    event.stopPropagation();

    if (keyPressed === 38) {
      if (isFocusAtBeginning) {
        nextFocusIndex = this.getNumberOfSiblings(visibleFacetsDropdownId);
      } else {
        nextFocusIndex = initialFocusHolder - 1;
      }
      this.setFacetFocus(visibleFacetsDropdownId, nextFocusIndex);
    } else if (keyPressed === 40) {
      if (isFocusAtEnd) {
        nextFocusIndex = 0;
      } else {
        nextFocusIndex = initialFocusHolder + 1;
      }
      this.setFacetFocus(visibleFacetsDropdownId, nextFocusIndex);
    }

    if (keyPressed === 13) {
      this.handleFacetEnterPress(event);
    }

    this.facetRefinementMenuHandler(event);
  }

  onFacetParentKeyUp(event) {
    const keyPressed = event.keyCode;
    const triggerKeys = [13, 38, 40];
    const shouldFocusOnChild = triggerKeys.indexOf(keyPressed) != -1;
    const wasInputEmpty = event.target.value.length === 1;
    const wasSpacebarPressed = keyPressed === 32;
    const relatedDropdownId = event.target.dataset.filterid;


    if (shouldFocusOnChild || (wasSpacebarPressed && wasInputEmpty)) {
      this.handleFacetInputClick();
      this.setFacetFocusFromParent(relatedDropdownId, 0);
    }

    this.facetRefinementMenuHandler(event);
  }

  getNumberOfSiblings(id) {
    const facetDropdownItems = this.getRelatedFacetGroupElements(id);
    return facetDropdownItems.length - 1;
  }

  getPositionInParentElement(id, node) {
    const facetDropdownItems = this.getRelatedFacetGroupElements(id);
    return Array.prototype.indexOf.call(facetDropdownItems, node);
  }

  setFacetFocus(element, index) {
    const nextElementToFocus = this.getAdjacentFacetGroupElement(element, index);
    if (typeof(nextElementToFocus) != 'undefined') {
      nextElementToFocus.focus();
    }
  }

  setFacetFocusFromParent(id, index) {
    const activeFacets = this.getRelatedFacetGroupElements(id);
    if (typeof(activeFacets[index]) != 'undefined') {
      activeFacets[index].focus(); 
    }
  }

  getAdjacentFacetGroupElement(id, index) {
    const facetDropdownItems = this.getRelatedFacetGroupElements(id);
    return facetDropdownItems[index];
  }

  getRelatedFacetGroupElements(id) {
    return document.getElementById(id).getElementsByTagName('li');
  }

  handleFacetEnterPress(event) {
    event.target.getElementsByClassName('search-facets__toggle')[0].click();
  }

  disableArrowKeyScrolling() {
    const arrowKeyHandler = function(e) {
      if([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
          e.preventDefault();
      }
    }
    window.addEventListener("keydown", arrowKeyHandler,false);
  }

  restoreArrowKeyScrolling() {
    if (typeof(arrowKeyHandler) != 'undefined') {
      window.removeEventListener("keydown", arrowKeyHandler, false);
    }
  }

  notNull(object) {
    return !!object;
  }

  isMatch(input) {
    return input.toLowerCase().indexOf(this.state.searchTerm) >= 0;
  }

  applyHighlighting(text) {
    var highlightedText,
        strIndex = text.toLowerCase().indexOf(this.state.searchTerm);

    if (strIndex >= 0) {
      var substrLength = this.state.searchTerm.length,
          titleLength = text.length,
          endSpan = "</span>",
          startSpan = "<span class='highlighted'>";
          
      highlightedText = text.slice(0, strIndex)
                        + startSpan
                        + text.slice(strIndex, strIndex + substrLength)
                        + endSpan
                        + text.slice(strIndex + substrLength, titleLength);
    }

    return highlightedText;
  }

  getFilteredFacets(searchTerm) {
      if (!this.state.searchTerm) {
        return this.props.group;
      }

      var facets = Object.assign({},this.props.group);

      facets.values = this.mapChildFacetMatch(facets.values);
      facets.childGroups = this.mapFacetMatches(facets.childGroups);
      
      var hasChildGroupMatch = !!facets.childGroups && facets.childGroups.length;
      var hasValuesMatch = !!facets.values && facets.values.length;
      
      if(hasChildGroupMatch || hasValuesMatch || this.isMatch(facets.label)) {
        return facets;
      } else {
        // facet filter has no results
        var emptyFacets = Object.assign({emptyFacets: true}, facets);
        return emptyFacets;
      }
  }

  mapFacetMatches(facetCollection) {
    if(!facetCollection) { return; }
    return facetCollection
      .map(facet => this.mapFacetOrChildMatch(facet, "label", "values"))
      .filter(this.notNull);
  }

  mapChildFacetMatch(childFacet) {
    if (!childFacet) { return; }
    return childFacet
      .map(facet => this.mapFacetOrChildMatch(facet, "name", "childValues"))
      .filter(this.notNull);
  }

  mapFacetOrChildMatch(childFacet, nameField, childrenField) {
    var newChildFacets = Object.assign({}, childFacet);
    newChildFacets[childrenField] = this.mapChildFacetMatch(newChildFacets[childrenField]);
    
    if (newChildFacets[childrenField] && newChildFacets[childrenField].length) {
      if (this.isMatch(newChildFacets[nameField])) {
        newChildFacets = Object.assign({displayName: this.applyHighlighting(newChildFacets[nameField])}, newChildFacets);
      }
      return newChildFacets;
    }
    
    if(newChildFacets[nameField]) {
      if (this.isMatch(newChildFacets[nameField])) {
        newChildFacets = Object.assign({displayName: this.applyHighlighting(newChildFacets[nameField])}, newChildFacets);
        return newChildFacets;
      }
    }
    
    return null;
  }

  handleFacetFilterChange(e) {
    this.setState({ showFacetDropdown: true });
    this.setState({searchTerm: e.target.value});
  }


  handleFacetInputClick(e) {
    this.setState({ showFacetDropdown: true });
  }

  handleFacetDropdownClick(e) {
    // toggle dropdown open or closed
    var shouldShowDropdown = this.state.showFacetDropdown ? false : true;
    this.setState({ showFacetDropdown: shouldShowDropdown });
  }

  // Handle closing dropdown when clicking outside
  mouseDownHandler(e) {
    this.setState({facetGroupClicked: true});
  }

  mouseUpHandler(e) {
    this.setState({facetGroupClicked: false});
  }

  onClearGroup() {
    const group = this.props.group;
    this.props.handleClearGroup(group);
  }



  // Rendering

  renderValueItem(value, recursionLevel) {
    const childValues = value.childValues;
    const hasChildValues = !!childValues && childValues.length > 0;
    const isExpanded = this.valueIsExpanded(value);
    const renderChildValues = hasChildValues && isExpanded;
    const toggleClass = "search-facets__value-expand is-open";
    const labelClass = value.selected ? "search-facets__title search-facet__selected" : "search-facets__title";
    const valueID = value.id.replace(/ /g, '_');


    return (
      <li key={value.id} className="search-facets__group-value" tabIndex="0" onFocus={ this.disableArrowKeyScrolling()} onKeyUp={ (e) => this.onFacetKeyUp(e) } >
        <input 
          className="search-facets__toggle" 
          checked={value.selected} 
          aria-controls="search-results"
          aria-selected={value.selected} 
          id={value.id} 
          onChange={ (e) => this.onFacetChange(e, value) } 
          type="checkbox"/>
        <label htmlFor={value.id} className={labelClass} dangerouslySetInnerHTML={{__html: value.displayName ? value.displayName : value.name}}></label>
        {hasChildValues && this.renderValues(childValues, recursionLevel + 1) }
      </li>
    );
  }

  renderFlatValues(values) {
    const renderValue = (value) => {
      return (
        <li key={value.id} tabIndex="0">
          <input id={value.id} checked={value.selected}  onChange={ (e) => this.onFacetChange(e, value) } type="checkbox"/>
          <label htmlFor={value.id}>{value.name} ({value.count})</label>
        </li>
      );
    };

    return (
      <ul className="search-facets__group-values">
        { values.map( value => renderValue(value)) }
      </ul>
    );
  }

  renderValues(values, recursionLevel) {
    let chunkedValues;
    if (recursionLevel === 0){
      chunkedValues = this.chunkValues(values);
    } else {
      chunkedValues = [values, []];
    }

    const headValues = chunkedValues[0];
    const tailValues = chunkedValues[1];

    const stateClass = `search-facets__group-values is-level-${recursionLevel}`;
    const shouldRenderTailValues = this.shouldRenderTailValues(tailValues);

    return (
      <div className="search-facets__values-wrapper">
        <ul className={stateClass}>
          {headValues.map( val => this.renderValueItem(val, recursionLevel) )}
        </ul>
        { shouldRenderTailValues && (
          <ul className={classnames(stateClass, "is-overflow")}>
            {tailValues.map( val => this.renderValueItem(val, recursionLevel) )}
          </ul>
        )}
      </div>
    );
  }

  renderChildGroups(group) {
    if (this.state.isGroupOpen
        && group.childGroups
        && group.childGroups.length) {

      return group.childGroups.map( childGroup => {
       return (<FacetGroup key={childGroup.id}
                           isChildGroup="true"
                           group={childGroup}
                           dupeSelected={false}
                           handleFacetChange={this.props.handleFacetChange}
                           updateSelectedFacets={this.props.updateSelectedFacets}
                           handleClearGroup={this.props.handleClearGroup}/>);
      });
    }
  }

  render() {
    const { dictionary } = this.props;
    const visibleFacets = this.getFilteredFacets(this.state.searchTerm);
    const isChildGroup = this.props.isChildGroup;
    const noResultsText = 'No results match "' + this.state.searchTerm + '"';
    const groupWrapperClass = this.state.showFacetDropdown ? "search-facets__group-wrapper search-facets__dropdown-active " : "search-facets__group-wrapper";
    const group = this.props.group;
    const selectedValues = selectedFacetsFromGroup(group);
    const showDupeSelected = selectedValues.length > 0 && this.props.dupeSelected;
    const dropdownID = visibleFacets.id + '_dropdown';
    const activeDescendant = this.state.activeDescendant;
    const isFacetVisible = !this.state.showFacetDropdown;

    return (
      <div className="search-facets__group" aria-live="polite" onMouseDown={(e) => this.mouseDownHandler(e)} onMouseUp={(e) => this.mouseUpHandler(e)}>
        <div className="search-facets__group-heading">
          <label className="search-facets__group-title" htmlFor={visibleFacets.id} dangerouslySetInnerHTML={{__html: visibleFacets.label}}></label>
          {!isChildGroup && (
            <div className="search-facets__input-wrapper">
              <input type="text"
                placeholder={dictionary.facetGroupPlaceholder}
                onClick={ (e) => this.handleFacetInputClick(e)}
                onChange={ (e) => this.handleFacetFilterChange(e)}
                onKeyUp={ (e) => this.onFacetParentKeyUp(e)}
                id={visibleFacets.id}
                role="combobox"
                aria-autocomplete="list"
                aria-expanded="true"
                aria-owns={dropdownID}
                aria-activedescendant={activeDescendant}
                aria-controls="search-results"
                aria-haspopup="true"
                data-filterid={dropdownID}
                className="search-facets__text-field"/>
              <span className="search-facets__dropdown-toggle" onClick={(e) => this.handleFacetDropdownClick(e)}></span>
            </div>
          )}
        </div>
        {!visibleFacets.emptyFacets && (
          <div className={groupWrapperClass}
               id={dropdownID}
               aria-hidden={isFacetVisible}
               ref={(wrap) => this.groupWrapper = wrap}>
              <div className="search-facets__group-body">
                  {this.renderValues(visibleFacets.values, 0)}
                  {this.renderChildGroups(visibleFacets)}
              </div>
          </div>)
        }
        {visibleFacets.emptyFacets &&
          (<div className="search-facets__no-results">{noResultsText}</div>)
        }
      </div>
    );
  }
}

const { func, shape, bool, number, string, oneOfType, arrayOf } = React.PropTypes;

// allows us to define propTypes recursively
// https://github.com/facebook/react/issues/5676
const lazyF = f => ((...args) => f().apply(this, args));

const facetValueShape = shape({
  id: oneOfType([number, string]).isRequired,
  name: string.isRequired,
  count: number.isRequired,
  selected: bool.isRequired,
  childValues: arrayOf(lazyF( () => facetValueShape ))
});

const facetGroupShape = shape({
  id: oneOfType([number, string]).isRequired,
  label: string.isRequired,
  values: arrayOf(facetValueShape).isRequired,
  childGroups: arrayOf(lazyF( () => facetGroupShape ))
});

FacetGroup.propTypes = {
  isOpen: bool,
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  updateSelectedFacets: func.isRequired,
  handleClearGroup: func.isRequired,
  group: facetGroupShape,
  dictionary: shape({
    facetGroupPlaceholder: string,
    facetGroupNoResults: string
  }) 
};

FacetGroup.defaultProps = {
  isOpen: true,
  dupeSelected: true
};
