import $ from 'jquery';

const eeClass = "experience-editor";

export default {
    init: checkForEE,
    editing: checkForEE()
};

function checkForEE() {
    return $("main").hasClass(eeClass);
}