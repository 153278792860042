import $ from "jquery";
const checkboxClass = ".cookie-warning__checkbox";
const $tabCheckbox = $(checkboxClass);
const $submitAll = $(".js-cookie-warning__button--submit-all");
const $submitSelected = $(".js-cookie-warning__button--submit");
const $selectAll = $(".js-cookie-warning__button--select-all");
const $cookiePolicyId = $("#cookiePolicyId");
const $displayFullBanner = $("#displayFullBanner");
const $cookieBanner = $("#js-cookie-warning");
const $form = $(".cookie-warning__form");
const $cookieBannerClose = $(".js-cookie-warning__close-button");

export default {init: cookieWarningSelect, selector: checkboxClass}

function cookieWarningSelect() {
    bindEvents();
}
function bindEvents() {
    $submitAll.on("click", submitAll);
    $selectAll.on("click", checkAll);
    $submitSelected.on("click", submitSelected);
    $cookieBannerClose.on("click", closeWindow);
}
function submitAll() {
    var cookieValue = getCookie("cookieSeen");
    if ($displayFullBanner.val() === "0" && cookieValue.indexOf($cookiePolicyId.val()) < 1) {
        checkAll();
    }
    setCookieSubmit();

    //Reload the window instead of posting to keep URL params
    window.location.reload();
    //$form.submit();
}
function checkAll() {
    $tabCheckbox.prop("checked", true);
}
function submitSelected() {
    setCookieSubmit();

    //Reload the window instead of posting to keep URL params
    window.location.reload();
    //$form.submit();

}

function closeWindow() {
    //Reload the window instead of posting to keep URL params
    window.location.reload();
}

function setCookieSubmit() {
    // var cookieValue = getCookie("cookieSeen");
    setCookie("cookieSeen", getCheckboxValue() + "|currlang:" + $("html").attr("lang"));
}
function getCheckboxValue() {
    let cookieContent = [];
    $tabCheckbox.each(function() {
        let cookieStatus = $(this).is(":checked")
            ? "1"
            : "0";
        cookieContent.push($(this).attr('id') + ":" + cookieStatus);

    });

    return cookieContent.join("|");
}
/* setCookie straight from w3schools; sets a cookie name and value for the whole domain, plus a 1-year expiry date */
function setCookie(cname, cvalue) {
    let d = new Date(new Date().setMonth(new Date().getMonth() + 6));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/* getCookie straight from w3schools; gets a specific cookie value */
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
