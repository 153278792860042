function nextVideojsVideo(vplayer, videoIDArray, index, forceAutoPlay, validVideoExists, isBannerVideo) {
    if (videoIDArray.length == 0) {
        return;
    }

    var cancelVideoRequests = false;
    if (isBannerVideo && videoIDArray.length === 1 && index === 1) {
        cancelVideoRequests = true;
    }

    if (videoIDArray.length <= index) {
        if (validVideoExists == false) {
            return;
        }
        index = 0;
    }

    var currentVid = videoIDArray[index];

    vplayer.catalog.getVideo(currentVid, function (error, video) {

        if (error) {
            vplayer.trigger("ended");
            return;
        }

        vplayer.catalog.load(video);
        validVideoExists = true;

        if (index > 0 || forceAutoPlay) {
            vplayer.play();
        }
    });

    if (cancelVideoRequests) {
        return;
    }

    vplayer.off("ended");
    vplayer.on("ended", function () { nextVideojsVideo(vplayer, videoIDArray, index + 1, forceAutoPlay, validVideoExists, isBannerVideo); });
}

function setVideojsBannerPlaylist(vplayer, videoIDArray) {
    nextVideojsVideo(vplayer, videoIDArray, 0, true, false, true);
}

function setVideojsVidComponentPlaylist(vplayer, videoIDArray) {
    nextVideojsVideo(vplayer, videoIDArray, 0, false, false, false);
}

$(function () {
    $("video").each(function (index) {
		var vPlayer = videojs(this.id);
        var options = {
            techOrder: ['html5']
        };
        var element = this;
		if(this.id.indexOf("bannerVid") >= 0)
		{
			vPlayer.muted(true);
			vPlayer.autoplay(true);
			//
			vPlayer.loop(true);
			vPlayer.controls(false);
            vPlayer.options(options);
			setVideojsBannerPlaylist(vPlayer, JSON.parse('[' + $(this).attr("data-video-ids") + ']'))
		}
		else
		{
			vPlayer.muted(false);
			vPlayer.autoplay(false);
			vPlayer.loop(false);
			vPlayer.controls(true);
			if ($(this).attr("data-video-ids"))
				setVideojsVidComponentPlaylist(vPlayer, JSON.parse('[' + $(this).attr("data-video-ids") + ']'))
		}
	});
});
