export default {
    set: setCookie,
    get: getCookie,
    delete: deleteCookie
}

/*
 * setCookie
 * straight from w3schools; sets a cookie name and value for the whole domain, plus a 1-year expiry date
 */
function setCookie(cname, cvalue) {
    let d = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";expires=" + expires + ";path=/";
}


/*
 * getCookie
 * straight from w3schools; gets a specific cookie value
 */
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
