import $ from "jquery";
import breakpoints from "./helpers/breakpoints.js";
import toggleNav from "./toggle-nav.js";
import restrictFocus from "./helpers/trap-focus.js";

const   debug = false,
        takeoverSelector = ".js-region-takeover",
        $regionTakeover = $(takeoverSelector);

var lastFocus,
    modalIsOpen = false;

export default {
    selector: takeoverSelector,
    bindClickHandlers: bindClickHandlers()
};

function setKeyboardTrap(){
    return restrictFocus.restrictFocus(event, $regionTakeover);
}

function closeWithEscape(event) {
    if (event.keyCode === 27) { // esc key
        if(debug){ console.debug("ESC pressed")}
        closeRegionTakeover();
    }
}

function openRegionTakeover(){
    if(debug){ console.debug("opening region takeover");}

    // visual stuff
    $("body").addClass("modal-is-open");
    $regionTakeover.removeClass("is-hidden");

    // small bkpt is only time mobile nav could be open
    if(breakpoints.find() === "small"){
        toggleNav.hideNav();
    }

    // accessibility stuff
    modalIsOpen = true;
    lastFocus = document.activeElement; // save last element focused
    $regionTakeover.attr("aria-hidden", "false");
    $regionTakeover.attr("tabindex", "0");
    // move the modal to the top of the body
    // let firstEl = $("body *:first");
    // $regionTakeover.insertBefore($(firstEl));
    $regionTakeover.trigger("focus");
    document.addEventListener("focus", setKeyboardTrap, true); // set keyboard trap
    document.addEventListener("keydown", closeWithEscape, true); // listen for ESC
}

function closeRegionTakeover(){
    if(debug){ console.debug("closing region takeover");}
    // visual stuff
    $("body").removeClass("modal-is-open");
    $regionTakeover.addClass("is-hidden");
    // accessibility stuff
    modalIsOpen = false;
    $regionTakeover.attr("aria-hidden", "true");
    $regionTakeover.attr("tabindex", "-1");
    document.removeEventListener("focus", setKeyboardTrap, true); // remove keyboard trap
    document.removeEventListener("keydown", closeWithEscape, true); // stop listening for ESC
    lastFocus.focus(); // return focus to the saved element
}

function bindClickHandlers() {
    $(function(){
        $(".js-open-region-takeover").on("click", openRegionTakeover);
        $(".js-close-region-takeover").on("click", closeRegionTakeover);
    });
}
