import R from "ramda";

// Utility function to map object paths to values
// See: https://github.com/ramda/ramda/wiki/Cookbook#flatten-a-nested-object-into-dot-separated-key--value-pairs
export function flattenObj(obj) {
  const go = obj_ => R.chain(([k, v]) => {
    if (typeof v == "object") {
      return R.map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
    } else {
      return [[k, v]];
    }
  }, R.toPairs(obj_));

  return R.fromPairs(go(obj));
}
