import R from 'ramda';
import { fetchSearchResults } from "../service";
import { RESULTS_DISPLAY_APPEND, RESULTS_DISPLAY_REFRESH } from "../resultsDisplay";
import { selectedFacetIdsByGroup, allSelectedFacets } from "../facets";

// Action Names
export const LOAD_RESULTS_START    = "SEARCH/LOAD_RESULTS_START";
export const LOAD_RESULTS_FAIL     = "SEARCH/LOAD_RESULTS_FAIL";
export const LOAD_RESULTS_SUCCESS  = "SEARCH/LOAD_RESULTS_SUCCESS";
export const MERGE_QUERY_PARAMS    = "SEARCH/MERGE_QUERY_PARAMS";
export const KEYWORDS_CHANGED      = "SEARCH/KEYWORDS_CHANGED";
export const INCREMENT_PAGE        = "SEARCH/INCREMENT_PAGE";
export const CHANGE_PAGE           = "SEARCH/CHANGE_PAGE";
export const SORT_CHANGED          = "SEARCH/SORT_CHANGED";
export const FACET_CHANGED         = "SEARCH/FACET_CHANGED";
export const CLEAR_FACET_GROUP     = "SEARCH/CLEAR_FACET_GROUP";
export const CLEAR_ALL_FACETS      = "SEARCH/CLEAR_ALL_FACETS";


// Single-dispatch Action Creators
export function mergeQueryParams(params, facets, preSelectedFacets) {
  return {
    type: MERGE_QUERY_PARAMS,
    payload: { params, facets, preSelectedFacets }
  };
}

export function loadStart() {
  return {
    type: LOAD_RESULTS_START,
    payload: null
  };
}

export function loadSuccess(response, resultsDisplay) {
  return {
    type: LOAD_RESULTS_SUCCESS,
    payload: {response, resultsDisplay}
  };
}

export function loadFail(err) {
  return {
    type: LOAD_RESULTS_FAIL,
    payload: err
  };
}

export function sortChanged(sorters) {
  return {
    type: SORT_CHANGED,
    payload: sorters
  };
}

export function incrementPage() {
  return {
    type: INCREMENT_PAGE,
    payload: null
  };
}

export function changePage(pageNum) {
  return {
    type: CHANGE_PAGE,
    payload: pageNum
  };
}

export function keywordsChanged(keywords) {
  return {
    type: KEYWORDS_CHANGED,
    payload: keywords
  };
}

export function facetChanged(facetId, facetValue) {
  return {
    type: FACET_CHANGED,
    payload: {facetId, facetValue}
  };
}

export function clearFacetGroup(group) {
  return {
    type: CLEAR_FACET_GROUP,
    payload: group
  };
}

export function clearAllFacets() {
  return {
    type: CLEAR_ALL_FACETS,
    payload: null
  };
}

// Multi-dispatch Action Creators
// Note that multiple dispatches inside an
// action creator only work when using the
// redux-thunk middleware. These action
// creators MUST return a function.
// https://github.com/gaearon/redux-thunk

export function loadResults(resultsDisplay = RESULTS_DISPLAY_APPEND) {
  return (dispatch, getState) => {
    const state = getState();
    const url = state.url;

    // get our query params, and add any pre-selected facets we
    // might have
    const query = R.merge(state.query, state.preSelectedFacets);

    const facets = selectedFacetIdsByGroup(getState().facets);

    // merge with pre-selected facets
    R.merge(facets, state.preSelectedFacets);

    dispatch(loadStart());
    fetchSearchResults(url, R.merge(query, facets))
      .then( response => {
        dispatch(loadSuccess(response, resultsDisplay));
      })
      .fail( error => {
        dispatch(loadFail(error));
      });
  };
}

export function loadNextPage() {
  return (dispatch) => {
    dispatch(incrementPage());
    dispatch(loadResults());
  };
}

export function loadNewPage(pageNum) {
  return (dispatch) => {
    dispatch(changePage(pageNum));
    dispatch(loadResults());
  };
}

export function loadNewSort(sorters) {
  return (dispatch) => {
    dispatch(sortChanged(sorters));
    dispatch(loadResults());
  };
}

export function loadNewKeywords(keywords) {
  return (dispatch) => {
    dispatch(keywordsChanged(keywords));
    dispatch(loadResults());
  };
}

export function loadFacetChange(facetId, facetValue) {
  return (dispatch) => {
    dispatch(facetChanged(facetId, facetValue));
    dispatch(loadResults());
  };
}

export function loadClearFacetGroup(group) {
  return (dispatch) => {
    dispatch(clearFacetGroup(group));
    dispatch(loadResults());
  };
}

export function loadClearAllFacets() {
  return (dispatch) => {
    dispatch(clearAllFacets());
    dispatch(loadResults());
  };
}

export function getSelectedFacets(facets) {
  return allSelectedFacets(facets)
}
