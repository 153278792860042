import $ from 'jquery';

const debug = false,
    large = 1199,
    medium = 799;

export default {large: large, medium: medium, init: init, find: findBreakpoint, current: findBreakpoint};

function init() {
    let currentBreakpoint = findBreakpoint();
    addBreakpointClass(currentBreakpoint);
}

function findBreakpoint() {
    if (debug) {
        console.debug("large breakpoint: " + large, ", medium breakpoint: " + medium);
    }

    // let windowWidth = $(window).outerWidth();
    let windowWidth = $(window).innerWidth();

    if (debug) {
        console.debug(windowWidth);
    }

    if (windowWidth <= medium) {
        return "small";
        if (debug) {
            console.debug("small");
        }
    } else if (windowWidth <= large) {
    if (debug) {
        console.debug("medium");
    }
        return "medium";
    } else {
        if (debug) {
            console.debug("large");
        }
        return "large";
    }
}

function addBreakpointClass(currentBreakpoint) {
    $("body").removeClass("small medium large");
    $("body").addClass(currentBreakpoint);
}
