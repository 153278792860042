import $ from "jquery";
window.$ = $;
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import breakpoints from "./helpers/breakpoints.js";
import ee from "./helpers/experience-editor.js";

const   debug = false,
        relatedExpertsSelector = ".js-related-experts";

var breakpoint;

export default {
    init: init,
    selector: relatedExpertsSelector
}

function init() {
    if(debug){ console.debug("loading related experts"); }

    // check breakpoint
    breakpoint = breakpoints.find();

    // handle multiple related experts components on a page
    $(relatedExpertsSelector).each(function(index, el){
        if (!(breakpoint === "large") && !ee.editing){
            slick($(el));
        }
    });

    bindHandlers();
}

function slick($el) {
    if(debug){ console.debug("slicking related experts"); }

    // unslick if already slicked
    if ($el.hasClass(".slick-initialized")) {
        $el.slick("unslick");
    }

    $el.slick({
        accessibility: true,
        speed: 700,
        easing: "swing",
        centerMode: true,
        centerPadding: "25px",
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: breakpoints.medium,
                settings: {
                    centerPadding: "75px"
                }
            },
            {
                breakpoint: breakpoints.large,
                settings: "unslick"
            }
        ]
    });
}

function preventClick(e) {
    let $block = $(e.target.closest(".slick-slide"));

    if ($block.hasClass("slick-slide") && !($block.hasClass("slick-current"))) {
        e.preventDefault();
    }
}

function bindHandlers() {
    $(function(){

        $(".expert-block__link").on("click", function(e) {
            preventClick(e);
        });

    });
}
