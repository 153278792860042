import React from "react";
import LoadingSpinner from "../components/LoadingSpinner.jsx";

export default class SearchBox extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        initiatedLoad: false
      };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
  }

  handleSubmit(event) {
      event.preventDefault();
      this.props.handleEntry(this.keywordInput.value);
      this.setState({ initiatedLoad: true });
  }
  
  render() {
    const { dictionary, keywords, loading } = this.props;
    return (
      <form className="search-box" onSubmit={e => this.handleSubmit(e)} noValidate>
        <div className="search-box__input-container">
          <label className="screen-reader-only" htmlFor="site-search">{dictionary.searchBoxPlaceholder}</label>
          <input id="site-search"
                 className="search-box__input"
                 type="text"
                 placeholder={dictionary.searchBoxPlaceholder}
                 defaultValue={keywords || ""}
                 ref={ input => this.keywordInput = input } />
          <button type="submit" className="search-box__button">
            <span className="screen-reader-only">Submit Search</span>
            <svg className="icon" role="img" aria-label="magnifying glass icon">
              <title>magnifying glass icon</title>
              <use xlinkHref="/assets/build/img/svg-sprite.svg#search-icon"></use>
            </svg>
          </button>
        </div>
      </form>
    );
  }
}

const { string, func, shape, bool } = React.PropTypes;

SearchBox.propTypes = {
  keywords: string,
  handleEntry: func.isRequired,
  loading: bool,
  dictionary: shape({
    searchBoxPlaceholder: string,
    searchBoxLabel: string
  }).isRequired
};

