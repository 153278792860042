import $ from "jquery";
import slickCarousel from "./helpers/slick-carousel/slick/slick.js";
import ee from "./helpers/experience-editor.js";
import breakpoints from "./helpers/breakpoints.js";
import waypoints from "./helpers/jquery.waypoints.js";

const debug = false,
    peopleRiverSectionSelector = ".people-river",
    peopleRiverSelector = ".js-people-river",
    mobileSlideSelector = ".js-people-river-mobile-slide",
    imagesPaneSelector = ".js-images-pane",
    colorBarsSelector = ".js-color-bars",
    singleBarSelector = ".js-color-bar";
let currentView = breakpoints.current();
export default {init: init, reset: reset, selector: peopleRiverSectionSelector};

let originalRiver = $(peopleRiverSectionSelector).html();

//create duplicate mobile version stored in mobileRiver
$("body").append('<div class="js-temp-mobile-river"></div>');
$(".js-temp-mobile-river").append(originalRiver);
$(".js-temp-mobile-river").find(imagesPaneSelector).remove();
$(".js-temp-mobile-river").find(colorBarsSelector).remove();
$(".js-temp-mobile-river").find(mobileSlideSelector).unwrap().unwrap();

$(".js-temp-mobile-river .js-slider-quote__quote").each(function (i) {
  if ($(this).text().length > 172) {
  let newText = $(this).text().substring(0, 172)+"...";
    $(this).text(newText);
  }
});

let mobileRiver = $(".js-temp-mobile-river").html();
$(".js-temp-mobile-river").remove();

function init() {
    if (debug) {
        console.debug("loading people river");
    }
    // check if we're showing the mobile or desktop view
    let isMobile = currentView === "small";
    $(peopleRiverSelector).each(function() {

        let $el = $(this);
        if(debug){
            console.debug("initial", $el);
        }
        setWaypoint($el);
        // make all the slides visible
        $el.addClass("is-slider");
        //create copy to deal with rotating tablet view
        createSlickSlider($el, isMobile);

        $el.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
            let visibleSlide = slick.$slides[nextSlide];
            animate($(visibleSlide));
        });

    });
}

function reset() {

    // recheck breakpoint
    let newScreenSize = breakpoints.current();
    if (debug) {
        console.debug("resize detected - people river " + breakpoints.current());
        console.debug("pre-resize breakpoint" + currentView)
    }
    // if we haven't switched views
    if (newScreenSize == currentView) {
        // do nothing
        if (debug) {
            console.debug("do nothing");
        }
        return;
    } else {
        // update current view
        currentView = newScreenSize;
        // recheck what view to use
        let isMobile = currentView === "small";
        if (debug) {
            console.debug("isMobile" + isMobile);
            console.debug(currentView);
        }
        // Only slick if not in EE
        if (!ee.editing) {
            $(peopleRiverSelector).each(function() {
                if ($(".people-river-section").find(".slick-initialized")) {
                    let $el = $(this);
                    $el.slick("unslick");

                    $(".people-section").find(".is-in-view").removeClass("is-in-view");

                    if (debug) {
                        console.debug("changing size");
                    }
                    setWaypoint($el);
                    createSlickSlider($el, isMobile);

                }
            });
        }
    }
}

function createSlickSlider($el, isMobile) {
    // Only slick if not in EE
    if (!ee.editing) {
        // In mobile, columns become the slides
        let isPortrait = window.innerHeight > window.innerWidth;
        if (debug) {
            console.debug("Height" + window.innerHeight);
            console.debug("Width" + window.innerWidth);
            console.debug("portrait?" + isPortrait);
        }
        if (isMobile) {
            alterMarkupForMobile($el);
        } else {
            if ($el.hasClass("people-river-mobile")) {
                $(".people-river").removeClass("people-river-mobile");
                $(".people-river").empty();
                $(".people-river").append(originalRiver);
            }
        }
        // make the actual slick slider
        $el.slick({
            infinite: true,
            speed: 800,
            cssEase: 'ease-out',
            easing: 'swing',
            slidesToShow: 1,
            accessibility: true,
            arrows: true,
            centerMode: true,
            variableWidth: true,
            centerPadding: "calc((100vw - breakpoints.large) / 2)",
            responsive: [
                {
                    breakpoint: breakpoints.large,
                    settings: {
                        centerPadding: "10vw",
                        variableWidth: false
                    }
                }
            ]
        });

    }
}

function alterMarkupForMobile($el) {
    //replace html with modified mobile version
    $(".people-river").empty();
    $(".people-river").append(mobileRiver);
    $(".people-river").addClass("people-river-mobile");
    // trigger mobile color bar animation on slide
    $el.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        let visibleSlide = slick.$slides[nextSlide];
        animate($(visibleSlide));
    });
}

function setWaypoint($el) {
    let waypoint = new Waypoint({
        element: $el,
        handler: function() {
            if (debug) {
                console.debug("waypoint hit");
                console.debug($el)
            }
            $el.addClass("is-in-view");
            animate($el.find(".slick-current"));
            waypoint.disable(); // only want to trigger each waypoint once
        },
        offset: "100%" // trigger when top of element hits bottom of viewport
    });
}

function animate($slide) {
    let itemsToAnimate = $slide.find(singleBarSelector);

    itemsToAnimate.each(function(index, el) {
      if(debug){
        console.debug("animate",el);
      }
        $(el).addClass("final-position");
    });
}
