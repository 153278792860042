import $ from "jquery";
const debug = false;

export default {
    restrictFocus: restrictFocus
}

function restrictFocus(event, $modal) {
    let modalIsOpen = $("body").hasClass("modal-is-open");
    if(debug){console.debug("modalIsOpen: " + modalIsOpen);}

    // we only want to check things if the modal is open
    if (modalIsOpen) {
        let modalContainsTarget = $.contains($modal.get(0), event.target);
        let targetIsModal = $modal.is(event.target);
        if(debug){
            console.debug(event.target);
            console.debug("targetIsModal: " + targetIsModal);
            console.debug("modalContainsTarget: " + modalContainsTarget);
        }

        // if the target is outside the modal, refocus the modal
        if (!modalContainsTarget && !targetIsModal) {
            event.stopPropagation();
            if(debug){ console.debug("focus sent back to modal")}
            $modal.focus();
        }
    }
}
