import $ from "jquery";

/*  Built with Mapbox.
    Documentation: https://docs.mapbox.com
*/
const debug = false,
    mapSelector = ".js-offices-map";

export default {
    init: init,
    selector: mapSelector
};

var map,
    offices,
    mapSettings = {
        accountName: "cdmsmith",
        publicToken: "pk.eyJ1IjoiY2Rtc21pdGgiLCJhIjoiY2l6cmwza2NsMDB5ejJxbzVubzNuZnhtOSJ9.JxAFaj7czJLP5MEBkvpKCQ",
        id: "officesMap",
        latitude: 6.148462,
        longitude: 32.510181,
        zoom: 1,
        minZoom: 1,
        maxZoom: 7,
        attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
        styleId: "cj02yc7o6001q2rpogii5kpxe",
        styleVersion: "v1",
        tileSize: 256 // in px
    };

function init() {
    if (debug) { console.debug("loading offices map"); }
    // note: officeDataJson printed directly to page
    offices = officeDataJson.Offices;
    createMap();
}

function createMap() {
    mapboxgl.accessToken = mapSettings.publicToken;
    map = new mapboxgl.Map({
        container: mapSettings.id,
        style: 'mapbox://styles/cdmsmith/cj02yc7o6001q2rpogii5kpxe',
        zoom: mapSettings.zoom,
        minZoom: mapSettings.minZoom,
        maxZoom: mapSettings.maxZoom,
        center: [mapSettings.longitude, mapSettings.latitude]
    });

    var nav = new mapboxgl.NavigationControl({
        showCompass: false
    });
    map.addControl(nav, 'top-left');

    buildOfficeMarkers();
}

function createOfficeMarker(office) {
    var el = buildMarkerIcon(office);
    new mapboxgl.Marker(el)
        .setLngLat([office.Longitude, office.Latitude])
        .setPopup(buildMarkerPopup(office))
        .addTo(map);
}

function buildOfficeMarkers() {
    $(offices).each(function (i, office) {
        createOfficeMarker(office);
    });
}

function buildMarkerIcon(office) {
    // Using font awesome icon
    var el = document.createElement('div');
    el.className = 'offices-map__marker';
    el.innerHTML = '<i class="fa fa-2x fa-map-marker" aria-hidden="true" title="' + office.Name + '" alt="' + office.Name + '" style="font-size: 3.75em;"></i>';

    return el;
}

function buildMarkerPopup(office) {
    let popupHtml = '';
    // popup wrapper
    popupHtml += '<div class="office-popup">';
    // office name section
    popupHtml += '<div class="office-popup__name">' + office.Name + '</div>';
    // street address section
    popupHtml += '<div class="office-popup__line">' + office.Address1 + '</div>';
    popupHtml += office.Address2 ? '<div class="office-popup__line">' + office.Address2 + '</div>' : '';
    popupHtml += office.Address3 ? '<div class="office-popup__line">' + office.Address3 + '</div>' : '';
    // city, state, zip, country section
    popupHtml += office.City || office.State || office.Zip || office.Country ? '<div class="office-popup__line">' : '';
    popupHtml += office.City ? office.City + ', ' : '';
    popupHtml += office.State ? office.State + ' ' : '';
    popupHtml += office.Country ? office.Country : '';
    popupHtml += office.City || office.State || office.Zip || office.Country ? '</div>' : '';
    // phone and fax section
    popupHtml += office.Phone ? '<div class="office-popup__line">' : '';
    popupHtml += office.Phone && office.PhoneLabel ? '<span class="office-popup__label">' + office.PhoneLabel + '</span> ' : '';
    popupHtml += office.Phone ? office.Phone + '</div>' : '';
    popupHtml += office.Fax ? '<div class="office-popup__line">' : '';
    popupHtml += office.Fax && office.FaxLabel ? '<span class="office-popup__label">' + office.FaxLabel + '</span> ' : '';
    popupHtml += office.Fax ? office.Fax + '</div>' : '';
    popupHtml += office.Email ? '<div class="office-popup__line">' : '';
    popupHtml += office.Email && office.EmailLabel ? '<span class="office-popup__label">' + office.EmailLabel + '</span> ' : '';
    popupHtml += office.Email ? '<a href="mailto:' + office.Email + '">' + office.Email + '</a></div>' : '';
    // close popup wrapper
    popupHtml += '</div>';

    return new mapboxgl.Popup().setHTML(popupHtml);
}
