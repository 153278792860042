import $ from "jquery";
import R from "ramda";
import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

import rootReducer from "./redux/rootReducer";
import { initialState as initialSearchState } from "./redux/search/search.reducer";
import { loadResults } from "./redux/search/search.actions";
import configureStore from "./redux/configureStore";
import createRoutingMiddleware from "./redux/createRoutingMiddleware";
import { mapStateToUrl, handleUrlChange } from "./redux/routing";

import { RESULTS_DISPLAY_APPEND, RESULTS_DISPLAY_REFRESH } from "./redux/resultsDisplay";

import ResultsContainer from "./containers/ResultsContainer.jsx";
import QueryContainer from "./containers/QueryContainer.jsx";
import FacetsContainer from "./containers/FacetsContainer.jsx";


// the mount nodes are the elements we want to include
// as part of the search app
export function initSearchApp(mountNodes) {

  if (!mountNodes.length) {
    return;
  }

  // extract the searchId from the mountNodes
  // while also checking mountNodes share same id
  const searchId = mountNodes.reduce((id, node) => {
    const nodeId = $(node).data("searchId");
    // if id is null, we want to set it
    // with the first node's value
    if (id === null) {
      return nodeId;
    }
    // if node id does not equal current
    // id value, it's invalid and return undefined
    if (id !== nodeId) {
      return;
    }

    return id;

  }, null);

  // if we have an invalid or non-matching id, raise an error
  if (!searchId) {
    throw new Error("Could not initialize Thread search app. searchId was either not present, or provided mount nodes did not share the same searchId");
  }

  // define the canonical inital state of our redux store here
  // including some value taken from the data-attrs of the
  // mount node.
  const config = window.threadSearchConfigs.find(c => c.searchId === searchId);
  if (!config) {
    throw new Error(`Could not initialize Thread search app instance with id of "${searchId}". Configuration data not found.`);
  }

  // create a starting state object,
  // which includes and intitial state
  // from individual reducers
  const defaultSorter = config.sorters.find(item => item.isActive === true);

  const initialState = R.merge(initialSearchState, {
    url: config.url,
    pagination: config.pagination,
    showStatus: config.showStatus,
    resultsDisplayStrategy: config.pagination ? RESULTS_DISPLAY_REFRESH : RESULTS_DISPLAY_APPEND,
    sorters: config.sorters,
    dictionary: config.dictionary,
    numOpenGroups: config.numOpenGroups,
    query: R.merge(initialSearchState.query, {
      pageid: config.pageid,
      sortby: defaultSorter ? defaultSorter.value : initialSearchState.query.sortby,
      sortorder: defaultSorter ? defaultSorter.direction : initialSearchState.query.sortorder
    })
  });

  const routingMiddleware = createRoutingMiddleware(mapStateToUrl, handleUrlChange);
  const store = configureStore(rootReducer, initialState, [thunk, routingMiddleware]);

  store.dispatch(loadResults());

  mountNodes.forEach((node) => {
    if (node.className.indexOf("js-search-query") > -1) {
      ReactDOM.render(
        <Provider store={store}>
          <QueryContainer />
        </Provider>,
        node
      );
    }

    if (node.className.indexOf("js-search-results") > -1) {
      ReactDOM.render(
        <Provider store={store}>
          <ResultsContainer />
        </Provider>,
        node
      );
    }

    if (node.className.indexOf("js-search-facets") > -1) {
      ReactDOM.render(
        <Provider store={store}>
          <FacetsContainer />
        </Provider>,
        node
      );
    }
  });
}
