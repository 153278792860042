import $ from "jquery";
import debounce from "./helpers/debounce.js";

const   debug = false,
        individualRightsRequestFormSelector = ".js-individual-rights-request-form",
        $form = $(individualRightsRequestFormSelector),

        $firstNameField = $(".js-individual-rights-request-form-firstname"),
        $middleInitialField = $(".js-individual-rights-request-form-middleinitial"),
        $lastNameField = $(".js-individual-rights-request-form-lastname"),
        $emailField = $(".js-individual-rights-request-form-email"),
        $telephoneField = $(".js-individual-rights-request-form-telephone"),
        $stateField = $(".js-individual-rights-request-form-state"),
        $relationshipField = $(".js-individual-rights-request-form-relationship"),
        $requestTypeField = $(".js-individual-rights-request-form-requesttype"),
        $messageField = $(".js-individual-rights-request-form-message"),
        $messageFieldMaxChars = 1000,
        $consentCheckbox = $(".js-individual-rights-request-form-consent"),

        $reCaptchaField = $(".email-form__captcha--invisible"),
        $submitBtn = $(".js-individual-rights-request-form-submit"),
        $cancelBtn = $(".js-individual-rights-request-form-cancel"),
        $formMessages = $(".js-individual-rights-request-form-error-message-box"),
        $successPage = $('#successpage');

export default {
    init: init,
    selector: individualRightsRequestFormSelector,
    bindHandlers: bindHandlers
};

function init() {
    if(debug) { console.debug("loading individual rights request form") }
    bindHandlers();
};

//function disableSubmit() {
//    if (!$submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", true);
//    }
//}

//function enableSubmit() {
//    if ($submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", false);
//    }
//}

function disableSubmit() {
    if (!$submitBtn.hasClass("disabled")) {
        $submitBtn.addClass("disabled");
    }
}

function enableSubmit() {
    if ($submitBtn.hasClass("disabled")) {
        $submitBtn.removeClass("disabled");
    }
}

function formIsValid() {
    return $form.get(0).checkValidity();
}

function checkFormValidity() {
    if (formIsValid() && checkCharacterValidity($messageField)) {
        enableSubmit();
    } else {
        disableSubmit();
    }
}

function checkInputValidity($inputField, clearFieldsWithErrorsOnly) {
    if (!$inputField.get(0).validity.valueMissing) {
        $inputField.parent().removeClass('error');
        $('.message-li-' + $inputField.attr('name')).removeClass('active-error');
    } else {
        if (!clearFieldsWithErrorsOnly) {
            $inputField.parent().addClass('error');
            $('.message-li-' + $inputField.attr('name')).addClass('active-error');
        }
    }
}

function checkInputFields(clearFieldsWithErrorsOnly) {
    checkInputValidity($firstNameField, clearFieldsWithErrorsOnly);
    checkInputValidity($lastNameField, clearFieldsWithErrorsOnly);
    checkInputValidity($messageField, clearFieldsWithErrorsOnly);
}

function checkEmailValidity(clearFieldsWithErrorsOnly) {
    if (!$emailField.get(0).validity.valueMissing) {
        if (!$emailField.get(0).validity.valid) {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().addClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).addClass('active-error');
            //showMessage();
        } else {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().removeClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
            //hideMessage();
        }
    } else {
        if (!clearFieldsWithErrorsOnly) {
            $emailField.parent().removeClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().addClass('error');
            $('.message-li-' + $emailField.attr('name')).addClass('active-error');
            //hideMessage();
        }
    }
}

function checkSelectValidity($inputField, clearFieldsWithErrorsOnly) {
    if (!$inputField.get(0).validity.valueMissing) {
        $inputField.parent().parent().removeClass('error');
        $('.message-li-' + $inputField.attr('name')).removeClass('active-error');
    } else {
        $inputField.parent().parent().addClass('error');
        $('.message-li-' + $inputField.attr('name')).addClass('active-error');
    }
}

function checkSelectFields(clearFieldsWithErrorsOnly) {
    checkSelectValidity($stateField, clearFieldsWithErrorsOnly);
    checkSelectValidity($relationshipField, clearFieldsWithErrorsOnly);
    checkSelectValidity($requestTypeField, clearFieldsWithErrorsOnly);
}

function checkTelephoneValidity(clearFieldsWithErrorsOnly) {
    if (!$telephoneField.get(0).validity.valueMissing) {
        if (!$telephoneField.get(0).validity.valid) {
            $telephoneField.parent().parent().removeClass('error');
            $('.message-li-' + $telephoneField.attr('name')).removeClass('active-error');
            $telephoneField.parent().parent().addClass('telephone-error');
            $('.message-li-invalid-' + $telephoneField.attr('name')).addClass('active-error');
            //showMessage();
        } else {
            $telephoneField.parent().parent().removeClass('error');
            $('.message-li-' + $telephoneField.attr('name')).removeClass('active-error');
            $telephoneField.parent().parent().removeClass('telephone-error');
            $('.message-li-invalid-' + $telephoneField.attr('name')).removeClass('active-error');
            //hideMessage();
        }
    } else {
        if (!clearFieldsWithErrorsOnly) {
            $telephoneField.parent().parent().removeClass('telephone-error');
            $('.message-li-invalid-' + $telephoneField.attr('name')).removeClass('active-error');
            $telephoneField.parent().parent().addClass('error');
            $('.message-li-' + $telephoneField.attr('name')).addClass('active-error');
            //hideMessage();
        }
    }
}

function checkConsentCheckboxValidity() {
    if (!$consentCheckbox.get(0).validity.valueMissing) {
        $consentCheckbox.attr("checked", "checked");
        $consentCheckbox.checked = true;
        $consentCheckbox.parent().parent().removeClass('error');
        $('.message-li-' + $consentCheckbox.attr('name')).removeClass('active-error');
        //hideMessage();
    } else {
        $consentCheckbox.attr("checked", ""); // For IE
        $consentCheckbox.removeAttr("checked"); // For other browsers
        $consentCheckbox.checked = false;
        $consentCheckbox.parent().parent().addClass('error');
        $('.message-li-' + $consentCheckbox.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

function clearCheckboxCheckedAttribute() {
    $consentCheckbox.attr("checked", ""); // For IE
    $consentCheckbox.prop("checked", "");
    $consentCheckbox.removeAttr("checked"); // For other browsers
    $consentCheckbox.checked = false;
}

function checkReCaptchaValidity() {
    if (!$reCaptchaField.get(0).validity.valueMissing) {
        $('.message-li-' + $reCaptchaField.attr('name')).removeClass('active-error');
        //hideMessage();
    } else {
        $('.message-li-' + $reCaptchaField.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

function checkMessageCharacterValidity() {
    if (!$messageField.get(0).validity.valueMissing) {
        if (!checkCharacterValidity($messageField)) {
            $messageField.parent().removeClass('error');
            $('.message-li-' + $messageField.attr('name')).removeClass('active-error');
            $messageField.parent().addClass('message-error');
            $('.message-li-invalid-' + $messageField.attr('name')).addClass('active-error');
            //showMessage();
        } else {
            $messageField.parent().removeClass('error');
            $('.message-li-' + $messageField.attr('name')).removeClass('active-error');
            $messageField.parent().removeClass('message-error');
            $('.message-li-invalid-' + $messageField.attr('name')).removeClass('active-error');
            //hideMessage();
        }
    } else {
        $messageField.parent().removeClass('message-error');
        $('.message-li-invalid-' + $messageField.attr('name')).removeClass('active-error');
        $messageField.parent().addClass('error');
        $('.message-li-' + $messageField.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

function checkCharacterValidity($inputField) {
    return (/^[A-Za-z0-9 .,\"'!@#$%&*()\-_\n\r]{0,1000}$/).test($inputField.val());
}

function submitForm() {
    let formData = $form.serialize();

    // add recaptcha if it exists in the form
    var captchaResponse = grecaptcha.getResponse();
    if (captchaResponse !== undefined) {
        formData += "&recaptcharesponse=" + encodeURIComponent(captchaResponse);
    }

    $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: formData
    })
		.done(function(success) {
		    if (!success) {
		        return emailFail();
		    }

		    //$formMessages.removeClass('error email-error');
		    //$formMessages.addClass('success');
		    //showMessage();

		    disableSubmit(); // prevent resubmission
            window.location.replace($successPage.val());
		})
		.fail(emailFail);
}

function emailFail() {
    //$formMessages.removeClass('success email-error');
    //$formMessages.addClass('error');
    //showMessage();
    // reset captcha if available
    grecaptcha.reset();
}

function showMessage() {
    $formMessages.addClass('active-error');
    $formMessages.attr("aria-hidden", "false");
}

function hideMessage() {
    $formMessages.removeClass('active-error');
    $formMessages.attr("aria-hidden", "true");
}

function bindHandlers(){
    // note: won't trigger if autocomplete (triggered from a different field) fills in an incorrect email format
    $emailField.on("blur", debounce(function() {
        if (document.activeElement.id != "cancelButton") {
            checkEmailValidity(false);
        } else {
            checkEmailValidity(true);
        }
        checkFormValidity();
    }, 250));

    $firstNameField.on("blur", debounce(function() {
        if (document.activeElement.id != "cancelButton") {
            checkInputValidity($firstNameField, false);
        } else {
            checkInputValidity($firstNameField, true);
        }
        checkFormValidity();
    }, 250));

    $lastNameField.on("blur", debounce(function() {
        if (document.activeElement.id != "cancelButton") {
            checkInputValidity($lastNameField, false);
        } else {
            checkInputValidity($lastNameField, true);
        }
        checkFormValidity();
    }, 250));

    $telephoneField.on("blur", debounce(function() {
        if (document.activeElement.id != "cancelButton") {
            checkTelephoneValidity(false);
        } else {
            checkTelephoneValidity(true);
        }
        checkFormValidity();
    }, 250));

    $stateField.on("change", debounce(function() {
        checkSelectValidity($stateField, false);
        checkFormValidity();
    }, 250));

    $relationshipField.on("change", debounce(function() {
        checkSelectValidity($relationshipField, false);
        checkFormValidity();
    }, 250));

    $requestTypeField.on("change", debounce(function() {
        checkSelectValidity($requestTypeField, false);
        checkFormValidity();
    }, 250));

    $messageField.on("blur", debounce(function() {
        if (document.activeElement.id != "cancelButton") {
            checkInputValidity($messageField, false);
        } else {
            checkInputValidity($messageField, true);
        }
        checkFormValidity();
    }, 250));

    $messageField.on("keyup", debounce(function() {
        checkMessageCharacterValidity();
        checkFormValidity();
    }, 250));

    $consentCheckbox.on("change", debounce(function() {
        checkConsentCheckboxValidity();
        checkFormValidity();
    }, 250));

    $form.on("keyup change", "input[required], textarea[required], select[required]", debounce(function() {
        checkInputFields(true);
        checkFormValidity();
    }, 250));
    
    $cancelBtn.on("click", function() {
        $('#email-form__telephoneMask').html("<i></i>XXX-XXX-XXXX");
        $(this).closest('form').find("input[type=text], input[type=email], input[type=tel], textarea, select").val("");
        $(this).closest('form').find("input[type=text], input[type=email], input[type=tel], textarea").parent().removeClass('error');
        $(this).closest('form').find("input[type=text], input[type=email], textarea").parent().removeClass('email-error');
        $(this).closest('form').find("input[type=text], input[type=tel], textarea").parent().parent().removeClass('telephone-error');
        $(this).closest('form').find("input[type=text], input[type=tel], textarea").parent().removeClass('message-error');
        $(this).closest('form').find("input[type=tel], input[type=checkbox]").parent().parent().removeClass('error');
        $(this).closest('form').find("select").parent().parent().removeClass('error');
        $('#textarea_feedback').html($messageFieldMaxChars + ' characters remaining');
        clearCheckboxCheckedAttribute();
        hideMessage();
        grecaptcha.reset();
        checkFormValidity();
    });

    $form.on("submit", function(e) {
        e.preventDefault();
        checkInputFields(false);
        checkEmailValidity(false);
        checkTelephoneValidity(false);
        checkSelectFields(false);
        checkMessageCharacterValidity();
        checkFormValidity();
        checkConsentCheckboxValidity();
        checkReCaptchaValidity();

        if (formIsValid() && checkCharacterValidity($messageField)) {
            submitForm();
            hideMessage();
            return true; // completes form submit action
        } else {
            showMessage();
            return false;
        }
    });

    $(function() {
        $('#textarea_feedback').html($messageFieldMaxChars + ' characters remaining');

        $messageField.keyup(function() {
            var text_length = $messageField.val().length;
            var text_remaining = $messageFieldMaxChars - text_length;

            $('#textarea_feedback').html(text_remaining + ' characters remaining');
        });
    });
}
