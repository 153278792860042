import $ from "jquery";
import restrictFocus from "./helpers/trap-focus.js";

const   debug = false,
        searchTakeoverSelector = ".js-search-takeover",
        $searchTakeover = $(".js-search-takeover"),
        $searchField = $(".js-search-takeover__searchbox"),
        $searchSubmit = $(".js-search-takeover__run-search"),
        $closeButton = $(".js-search-takeover__close-button"),
        $openButton = $(".js-nav__link--search");

var lastFocus,
    modalIsOpen = false;

var siteLanguage = $("html").attr("lang");

export default {
    selector: searchTakeoverSelector,
    init: init
}

function init() {
    bindEventHandlers();
}

function setKeyboardTrap(){
    return restrictFocus.restrictFocus(event, $searchTakeover);
}

function closeWithEscape(event) {
    if (event.keyCode === 27) { // esc key
        if(debug){ console.debug("ESC pressed")}
        closeSearchTakeover();
    }
}

function openSearchTakeover() {
    // visual stuff
    $("body").addClass("modal-is-open");
    $searchTakeover.removeClass("is-hidden");
    // accessibility stuff
    modalIsOpen = true;
    lastFocus = document.activeElement; // save last element focused
    $searchTakeover.attr("aria-hidden", "false");
    $searchTakeover.attr("tabindex", "0");
    // move the modal to the top of the body
    let firstEl = $("body *:first");
    $searchTakeover.insertBefore($(firstEl));
    $searchField.trigger("focus");
    document.addEventListener("focus", setKeyboardTrap, true); // set keyboard trap
    document.addEventListener("keydown", closeWithEscape, true); // listen for ESC
}

function closeSearchTakeover() {
    // visual stuff
    $("body").removeClass("modal-is-open");
    $searchTakeover.addClass("is-hidden");
    // accessibility stuff
    modalIsOpen = false;
    $searchTakeover.attr("aria-hidden", "true");
    $searchTakeover.attr("tabindex", "-1");
    document.removeEventListener("focus", setKeyboardTrap, true); // remove keyboard trap
    document.removeEventListener("keydown", closeWithEscape, true); // stop listening for ESC
    lastFocus.focus(); // return focus to the saved element
}

function runTakeoverSearch() {
    let query = $searchField.val();
    // send them to search page with query parameter
    // note: it's ok to let them search without a query
    window.location = "/" + siteLanguage + "/search?q=" + query;
    return false;
}

function bindEventHandlers() {
    $(function(){
        $openButton.on("click", openSearchTakeover);

        $closeButton.on("click", closeSearchTakeover);

        $searchSubmit.on("click", function(event) {
            // run through our custom function instead of standard submit
            runTakeoverSearch();
            return false;
        });

        $searchField.on("keypress", function(event) {
            if (event.keyCode === 13) { // enter key
                // run through our custom function instead of standard submit
                runTakeoverSearch();
                return false;
            }
        });
    });
}
