import $ from "jquery";

const 	debug = false,
		addtoanyToggleSelector = ".at-expanding-share-button-toggle-btn",
		warningClass = "addtoany-new-window-warning";

export default {
	init: init,
	selector: addtoanyToggleSelector
};

function init() {
	bindHandlers();
}

function addExternalLinkWarning(event) {
	if(debug) {console.log(event.target);}
	let $toggle = $(event.target);
	// note: addtoany_external defined in main_layout.cshtml
	let externalHtml = 	'<i class="fa fa-external-link"></i>'
						+ '<span class="screen-reader-only">'
						+ addtoany_external + '</span>';

	$toggle.addClass(warningClass);
	$toggle.prepend(externalHtml);

	$("body").off("mouseenter focus", addtoanyToggleSelector, addExternalLinkWarning);
}

function bindHandlers(){
	$(function(){
		$("body").on("mouseenter focus", addtoanyToggleSelector, addExternalLinkWarning);
	});
}
