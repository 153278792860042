import $ from "jquery";
import debounce from "./helpers/debounce.js";

const   debug = false,
        emailFormSelector = ".js-email-form",
        $form = $(emailFormSelector),
        $formErrorMessages = $(".js-email-form-message"),
        $submitBtn = $(".js-email-form-submit"),
        $cancelBtn = $(".js-email-form-cancel"),
        $categoryField = $(".js-email-form-category"),
        $emailField = $(".js-email-form-email"),
        $nameField = $(".js-email-form-name"),
        $telephoneField = $(".js-email-form-telephone"),
        $subjectField = $(".js-email-form-subject"),
        $messageField = $(".js-email-form-messagebody"),
        $reCaptchaField = $(".email-form__captcha--invisible"),
        $expansionContainer = $(".js-email-form-expansion-container"),
        $expansionContent = $(".js-email-form-expansion-content"),
        $successPage = $('#successpage');

export default {
    init : init,
    selector: emailFormSelector,
    bindHandlers: bindHandlers
};

function init() {
    if(debug) { console.debug("loading email form") }
    bindHandlers();
    setExpansionHeight();
};

//function disableSubmit() {
//    if (!$submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", true);
//    }
//}

//function enableSubmit() {
//    if ($submitBtn.prop("disabled")) {
//        $submitBtn.prop("disabled", false);
//    }
//}

function disableSubmit() {
    if (!$submitBtn.hasClass("disabled")) {
        $submitBtn.addClass("disabled");
    }
}

function enableSubmit() {
    if ($submitBtn.hasClass("disabled")) {
        $submitBtn.removeClass("disabled");
    }
}

function formIsValid() {
    return $form.get(0).checkValidity();
}

function checkFormValidity() {
    if (formIsValid()) {
        enableSubmit();
    } else {
        disableSubmit();
    }
}

function checkInputValidity($inputField, clearFieldsWithErrorsOnly) {
    if (!$inputField.get(0).validity.valueMissing) {
        $inputField.parent().removeClass('error');
        $('.message-li-' + $inputField.attr('name')).removeClass('active-error');
    } else {
        if (!clearFieldsWithErrorsOnly) {
            $inputField.parent().addClass('error');
            $('.message-li-' + $inputField.attr('name')).addClass('active-error');
        }
    }
}

function checkInputFields(clearFieldsWithErrorsOnly) {
    checkInputValidity($nameField, clearFieldsWithErrorsOnly);
    checkInputValidity($subjectField, clearFieldsWithErrorsOnly);
    checkInputValidity($messageField, clearFieldsWithErrorsOnly);
}

function checkEmailValidity() {
    if (!$emailField.get(0).validity.valueMissing) {
        if (!$emailField.get(0).validity.valid) {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().addClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).addClass('active-error');
        } else {
            $emailField.parent().removeClass('error');
            $('.message-li-' + $emailField.attr('name')).removeClass('active-error');
            $emailField.parent().removeClass('email-error');
            $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
        }
    } else {
        $emailField.parent().removeClass('email-error');
        $('.message-li-invalid-' + $emailField.attr('name')).removeClass('active-error');
        $emailField.parent().addClass('error');
        $('.message-li-' + $emailField.attr('name')).addClass('active-error');
    }
}

function checkSelectValidity() {
    if (!$categoryField.get(0).validity.valueMissing) {
        $categoryField.parent().parent().removeClass('error');
        $('.message-li-' + $categoryField.attr('name')).removeClass('active-error');
    } else {
        $categoryField.parent().parent().addClass('error');
        $('.message-li-' + $categoryField.attr('name')).addClass('active-error');
    }
}

function cleanTelephoneNumber() {
    let phoneNum = $telephoneField.val();
    if (phoneNum) {
        phoneNum = phoneNum.replace(/\D/g,'');
        $telephoneField.val(phoneNum);
    }
}

function setExpansionHeight() {
    let height = $expansionContent.height();
    // round up to whole px for cross browser render issues
    height = Math.ceil(height);
    // setting container height allows for % top position of child for animation
    $expansionContainer.css("height", (height + 100));
}

function showFormExpansion() {
    if (!$expansionContainer.hasClass("is-visible")) {
        $expansionContainer.addClass("is-visible");
        $expansionContent.attr("aria-hidden", "false");
    }
}

function checkReCaptchaValidity() {
    if (!$reCaptchaField.get(0).validity.valueMissing) {
        $('.message-li-' + $reCaptchaField.attr('name')).removeClass('active-error');
        //hideMessage();
    } else {
        $('.message-li-' + $reCaptchaField.attr('name')).addClass('active-error');
        //hideMessage();
    }
}

function submitForm() {
    let formData = $form.serialize();

	// add recaptcha if it exists in the form
    var captchaResponse = grecaptcha.getResponse();
    if (captchaResponse !== undefined) {
    	formData += "&recaptcharesponse=" + encodeURIComponent(captchaResponse);
	  }

	$.ajax({
			type: 'POST',
			url: $form.attr('action'),
			data: formData
		})
		.done(function(success) {
			if (!success) {
				return emailFail();
			}

		    //$formMessages.removeClass('error email-error');
		    //$formMessages.addClass('success');
		    //showMessage();

			disableSubmit(); // prevent resubmission
            window.location.replace($successPage.val());
		})
		.fail(emailFail);
}

function emailFail() {
    //$formMessages.removeClass('success email-error');
    //$formMessages.addClass('error');
    //showMessage();
    // reset captcha if available
    grecaptcha.reset();
}

function showMessage() {
    $formErrorMessages.addClass('active-error');
    $formErrorMessages.attr("aria-hidden", "false");
}

function hideMessage() {
    $formErrorMessages.removeClass('active-error');
    $formErrorMessages.attr("aria-hidden", "true");
}

function bindHandlers() {
    $categoryField.on("change", debounce(function() {
        checkSelectValidity();
        checkFormValidity();
    }, 250));

    // note: won't trigger if autocomplete (triggered from a different field) fills in an incorrect email format
    $emailField.on("blur", debounce(function(){
        checkEmailValidity();
        checkFormValidity();
    }, 250));

    $nameField.on("blur", debounce(function() {
        checkInputValidity($nameField, false);
        checkFormValidity();
    }, 250));

    $subjectField.on("blur", debounce(function() {
        checkInputValidity($subjectField, false);
        checkFormValidity();
    }, 250));

    $messageField.on("blur", debounce(function() {
        checkInputValidity($messageField, false);
        checkFormValidity();
    }, 250));

    $form.on("keyup change", "input[required], textarea[required], select[required]", debounce(function(){
        checkInputFields(true);
        checkFormValidity();
    }, 250));

    $cancelBtn.on("click", function() {
        $(this).closest('form').find("input[type=text], input[type=email], input[type=tel], textarea, select").val("");
        $(this).closest('form').find("input[type=text], input[type=email], input[type=tel], textarea").parent().removeClass('error');
        $(this).closest('form').find("input[type=text], input[type=email], textarea").parent().removeClass('email-error');
        $categoryField.parent().parent().removeClass('error');
        hideMessage();
        grecaptcha.reset();
        checkFormValidity();
    });

    $form.on("focus", "input, select", function() {
        showFormExpansion();
    });

    $form.on("submit", function(e) {
        e.preventDefault();
        cleanTelephoneNumber();
        checkSelectValidity();
        checkInputFields(false);
        checkEmailValidity();
        checkFormValidity();
        checkReCaptchaValidity();
        if (formIsValid()) {
            submitForm();
            hideMessage();
            return true; // completes form submit action
        } else {
            showMessage();
            $(".error").filter(":first").trigger("focus");
            return false;
        }
    });
}
